import { createSWRProxyHooks } from '@trpc-swr/client';
import superjson from 'superjson';
import { createTRPCClientProxy, httpBatchLink, TRPCLink } from '@trpc/client';
import type { AppRouter } from 'shared/api';
import { allHeaders, fetchWrapper } from './utils';

export const trpc = createSWRProxyHooks<AppRouter>({
  transformer: superjson,
  links: [
    httpBatchLink({
      fetch: fetchWrapper,
      // TODO: fix hardcoded
      url: '/api/trpc',
      maxURLLength: 2083,
      headers: () => allHeaders(),
    }),
  ],
});
export const reactClient = trpc.createClient();
export const client = createTRPCClientProxy(reactClient);
