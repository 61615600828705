import { FeaturedIcon } from './FeaturesIcon';
import PhotoOffIcon from 'assets/svg/tabler/photo-off.svg';
export default function EmptyEditorContent(_: Record<any, string>) {
  return (
    <div className="flex flex-col gap-4 items-center justify-center text-center grow">
      <FeaturedIcon
        size="lg"
        color="primary-light"
        iconType="circle"
        outline
        icon={PhotoOffIcon}
      />
      <div className="flex flex-col gap max-w-[24rem]">
        <div className="text-md-medium text-gray-900">No item selected</div>
        <div className="text-sm-light text-gray-500">
          Please select one or more items to update details. If no content is
          available, upload by clicking or dragging files to the import tile.
        </div>
      </div>
    </div>
  );
}
