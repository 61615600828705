import { NavLink, Outlet } from 'react-router-dom';

function NavItem(props: { to: string; label: string }) {
  return (
    <NavLink to={props.to}>
      {({ isActive }) => {
        return (
          <div
            className={`px-3 py-2 text-md-medium rounded-md hover:bg-gray-50 hover:text-gray-600 ${
              isActive ? 'bg-primary-50 text-primary-700' : 'text-gray-700'
            }`}
          >
            {props.label}
          </div>
        );
      }}
    </NavLink>
  );
}
export default function ManageContentPage() {
  return (
    <div className="flex flex-col lg:flex-row justify-stretch w-full h-full">
      <nav className="py-9 px-4 border-b lg:border-b-0 lg:border-r border-gray-200">
        <div className="pb-4">Manage Content</div>
        <div className="flex flex-row lg:flex-col gap-0.5 w-64 h-full">
          <NavItem to={'/admin/manage/locations'} label="Locations"></NavItem>
          <NavItem to={'/admin/manage/images'} label="Images"></NavItem>
          <NavItem to={'/'} label="Test"></NavItem>
          <NavItem to={'/'} label="Test"></NavItem>
          <NavItem to={'/'} label="Test"></NavItem>
        </div>
      </nav>
      <Outlet></Outlet>
    </div>
  );
}
