import { LoggedOutRoutes } from 'client/routes';
import { StrictMode } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AlertManagerContext, AlertManager } from './alertManager';
import init from './app';

const router = createBrowserRouter(LoggedOutRoutes);

function ReactRoot() {
  return (
    <StrictMode>
      <AlertManagerContext.Provider value={new AlertManager([])}>
        <RouterProvider router={router}></RouterProvider>
      </AlertManagerContext.Provider>
    </StrictMode>
  );
}

init(ReactRoot);
