import { cva } from 'class-variance-authority';
import NotificationIcon from 'assets/svg/tabler/alert-circle.svg';

const inputPaddingLeftLeadingIcon = 'pl-[2.625rem]';
const inputPaddingLeft = 'pl-3.5';
const inputPaddingRightNotificationIcon = 'pr-7.5';
const inputPaddingRight = 'pr-3.5';

const leadingIconClasses =
  'w-5 h-5 text-gray-500 absolute left-3.5 top-1/2 -translate-y-1/2 stroke-[1]';
const messageClasses = cva(['text-sm-light', 'whitespace-pre-line'], {
  variants: {
    messageType: {
      error: 'text-error-500',
      default: 'text-gray-500',
    },
  },
});
const notificationIconClasses = cva(
  [
    'w-4',
    'h4',
    'absolute',
    'right-3.5',
    'top-1/2',
    '-translate-y-1/2',
    'text-error-500',
    'stroke-[1]',
  ],
  {
    variants: {
      messageType: {
        error: 'flex',
        default: 'hidden',
      },
    },
  }
);
const textInputClasses = cva(
  [
    'w-full',
    'py-3',
    'border',
    'rounded-lg',
    'appearance-none',
    'text-gray-900',
    'text-md-light',
    'placeholder:text-gray-500',
    'disabled:cursor-default',
    'disabled:text-gray-500',
    'disabled:bg-gray-50',
    // TODO: is this safe in all cases?
    'grow',
  ],
  {
    variants: {
      messageType: {
        error: ['border-error-300', inputPaddingRightNotificationIcon],
        default: ['border-gray-300', inputPaddingRight],
      },
      resize: {
        none: 'resize-none',
        horizontal: 'resize-x',
        vertical: 'resize-y',
        both: 'resize',
      },
      icon: {
        true: inputPaddingLeftLeadingIcon,
        false: inputPaddingLeft,
      },
    },
  }
);

interface SharedType {
  label?: string;
  message?: string;
  leadingIcon?: React.FunctionComponent<{ className?: string }>;
  messageType?: 'default' | 'error';
  resize?: 'horizontal' | 'vertical' | 'both' | 'none';
  className?: string;
  required?: boolean;
  stretch?: '';
}
type TextLikeInput =
  | 'text'
  | 'email'
  | 'password'
  | 'search'
  | 'tel'
  | 'url'
  | 'date';
type FauxType = React.PropsWithChildren<SharedType> & { type: 'faux' };
type SharedInputType = SharedType & {
  placeholder?: string;
  value?: string;
  name: string;
  onInput?: (value: string) => void;
};
export type InputType = SharedInputType & {
  type: TextLikeInput;
  passthrough?: React.ComponentProps<'input'>;
};
export type TextAreaType = SharedInputType & {
  type: 'textarea';
  passthrough?: React.ComponentProps<'textarea'>;
};
export type PropType = FauxType | InputType | TextAreaType;

export default function TextInput(props: PropType) {
  const className = props.className ?? '';
  const messageType = props.messageType ?? 'default';
  const message = props.message ?? '';
  const label = props.label ?? '';
  const resize = props.resize ?? 'none';
  const required = props.required ?? false;

  const cvaProps = {
    icon: !(props.leadingIcon == null),
    resize,
    messageType,
  };

  let input;
  if (props.type === 'faux') {
    input = <div className={textInputClasses(cvaProps)}>{props.children}</div>;
  } else {
    const placeholder = props.placeholder ?? '';
    const value = props.value ?? '';
    if (props.type === 'textarea') {
      input = (
        <textarea
          className={textInputClasses(cvaProps)}
          name={props.name}
          placeholder={placeholder}
          value={value}
          required={required}
          onInput={(el) => {
            if (props.onInput) {
              props.onInput(el.currentTarget.value);
            }
          }}
          {...props.passthrough}
        ></textarea>
      );
    } else {
      input = (
        <input
          className={textInputClasses(cvaProps)}
          name={props.name}
          type={props.type}
          placeholder={placeholder}
          value={value}
          required={required}
          onInput={(el) => {
            if (props.onInput) {
              props.onInput(el.currentTarget.value);
            }
          }}
          {...props.passthrough}
        ></input>
      );
    }
  }

  return (
    <label className={`${className} flex flex-col gap-1.5`}>
      {label || required ? (
        <div>
          {label ? (
            <span className="text-sm-medium text-gray-700">{label}</span>
          ) : null}
          {required ? (
            <span className="text-sm-medium text-error-500" aria-hidden>
              *
            </span>
          ) : null}
        </div>
      ) : null}
      <div className="relative grow flex">
        {props.leadingIcon != null && (
          <props.leadingIcon className={leadingIconClasses} />
        )}
        {input}
        <NotificationIcon className={notificationIconClasses(cvaProps)} />
      </div>
      {message ? (
        <div className="flex flex-row items-center gap-1.6">
          <span className={messageClasses(cvaProps)}>{message}</span>
        </div>
      ) : null}
    </label>
  );
}
