import { Link, NavLink, Outlet } from 'react-router-dom';
import Logo from 'assets/svg/custom/logo.svg';
import Down from 'assets/svg/tabler/chevron-down.svg';
import Up from 'assets/svg/tabler/chevron-up.svg';
import MenuIcon from 'assets/svg/tabler/menu.svg';
import XIcon from 'assets/svg/tabler/x.svg';
import Button from './Button';
import HeaderCategory from './HeaderCategory';
import { Menu, Disclosure } from '@headlessui/react';
import { observer } from 'mobx-react-lite';
import { AlertManagerContext } from 'client/alertManager';
import { useContext } from 'react';
import { Alert } from './Alert';
import { client } from 'shared/trpc';
import { ignorePromise } from 'shared/utils';

export interface NavigationItem {
  title: string;
  subtitle: string;
  icon: React.FunctionComponent<{ className?: string }>;
  to: string;
}
export interface NavigationCategory {
  label: string;
  items: NavigationItem[];
}

export function HeaderDropdown(props: { category: NavigationCategory }) {
  return (
    <Menu>
      {({ open }) => (
        <div className="relative inline-block">
          <Menu.Button
            as={Button}
            trailingIcon={open ? Up : Down}
            size="lg"
            displayType="link"
            hasColor={open}
            label={props.category.label}
          />
          <div className="absolute top-[calc(100%_+_1rem)] min-w-[21rem] focus:ring-none z-10">
            <HeaderCategory category={props.category} />
          </div>
        </div>
      )}
    </Menu>
  );
}

export function SidebarDropdown(props: { category: NavigationCategory }) {
  return (
    <Menu>
      {({ open }) => (
        <div className="flex flex-col gap-2">
          <Menu.Button className="text-md-medium text-gray-900 border-0 bg-transparent cursor-pointer flex justify-between py-3 px-1">
            <span>{props.category.label}</span>
            {open ? (
              <Up className="text-gray-500 stroke-[2]" />
            ) : (
              <Down className="text-gray-500 stroke-[2]" />
            )}
          </Menu.Button>
          <div className="flex flex-col flex-nowrap items-stretch focus:ring-none">
            <HeaderCategory category={props.category} />
          </div>
        </div>
      )}
    </Menu>
  );
}
export function MainSidebar(props: { categories: NavigationCategory[] }) {
  return (
    <Disclosure>
      {({ open }) => (
        <nav className="bg-base-white lg:hidden flex w-full flex-col flex-nowrap items-center justify-center">
          <div className="flex flex-row flex-nowrap w-full min-h-[4.5rem] items-center border-b border-gray-100">
            <Link to="/" className="flex mr-auto pl-4">
              <Logo />
            </Link>
            {props.categories.length >= 1 ? (
              <Disclosure.Button className="flex border-0 bg-transparent p-3 m-0 items-center justify-center">
                {open ? (
                  <XIcon className="stroke-[2]" />
                ) : (
                  <MenuIcon className="stroke-[2]" />
                )}
              </Disclosure.Button>
            ) : null}
          </div>
          {props.categories.length >= 1 ? (
            <Disclosure.Panel className="w-full flex flex-col flex-nowrap">
              <div className="flex flex-col flex-nowrap gap-2 py-6 px-3">
                {props.categories.map((category) => (
                  <SidebarDropdown key={category.label} category={category} />
                ))}
              </div>
              <div className="border-t border-gray-100 py-6 px-4">
                <Button
                  size="lg"
                  displayType="primary"
                  label="Logout"
                  stretch="horizontal"
                />
              </div>
            </Disclosure.Panel>
          ) : null}
        </nav>
      )}
    </Disclosure>
  );
}
export function Header(props: { categories: NavigationCategory[] }) {
  return (
    <nav className="hidden lg:flex justify-between w-full border-b border-gray-100 bg-base-white">
      <div className="m-auto flex grow flex-row flex-nowrap items-center justify-center px-7 min-h-[6rem] max-w-[80rem]">
        <div className="flex flex-1 justify-start">
          <Link to="/" className="flex mr-auto">
            <Logo />
          </Link>
        </div>
        {props.categories.length >= 1 ? (
          <>
            <div className="flex flex-1 justify-center">
              <div className="flex flex-row flex-nowrap gap-8">
                {props.categories.map((category) => (
                  <HeaderDropdown key={category.label} category={category} />
                ))}
              </div>
            </div>
            <div className="flex flex-1 justify-end">
              <Button
                size="lg"
                displayType="primary"
                label="Logout"
                onClick={ignorePromise(async () => {
                  await client.logout.mutate();
                  window.location.reload();
                })}
              />
            </div>
          </>
        ) : null}
      </div>
    </nav>
  );
}

export function FooterCategory(props: { category: NavigationCategory }) {
  return (
    <div className="flex flex-col">
      <h2 className="text-md-semibold mb-4 text-base-black">
        {props.category.label}
      </h2>
      <ul className="overflow-hidden flex flex-col flex-nowrap items-start gap-2">
        {props.category.items.map((item) => (
          <NavLink
            key={item.title}
            className="text-md-light text-gray-500 hover:text-primary-600 "
            to={item.to}
          >
            <span>{item.title}</span>
          </NavLink>
        ))}
      </ul>
    </div>
  );
}

export function Footer(props: { categories: NavigationCategory[] }) {
  return (
    <footer className="flex w-full flex-col flex-nowrap items-center justify-center border-t border-gray-10 bg-base-white">
      {props.categories.length >= 1 ? (
        <nav className="hidden lg:flex flex-row mx-w-[80rem] flex-nowrap px-8 pt-8 pb-20 w-full items-start justify-between">
          <NavLink to="/" className="flex pr-16">
            <Logo />
          </NavLink>
          <div className="flex flex-row flex-nowrap gap-8">
            {props.categories.map((category) => (
              <FooterCategory key={category.label} category={category} />
            ))}
          </div>
        </nav>
      ) : null}
      <div className="bg-gray-50 w-full">
        <small className="text-sm-light lg:text-md-light m-auto max-w-[80rem] text-gray-500 flex flex-row flex-nowrap justify-between py-6 lg:py-12 px-8">
          <span className="m-auto lg:m-0">
            2022 © Kreitzberg Archives. All rights reserved.
          </span>
          <span className="hidden lg:block">
            Built with ❤️ in Hamburg, Germany
          </span>
        </small>
      </div>
    </footer>
  );
}

export const MainNavigation = observer(
  (props: React.PropsWithChildren<{ categories: NavigationCategory[] }>) => {
    const alertManager = useContext(AlertManagerContext);
    return (
      <div className="min-h-screen flex flex-col">
        <Header categories={props.categories}></Header>
        <MainSidebar categories={props.categories}></MainSidebar>
        <div className="grow basis-0">
          {alertManager.hasAlerts ? (
            <div className="relative">
              <div className="absolute w-full flex gap-4 flex-col p-6 z-20">
                {alertManager.alerts.map((alert, idx) => (
                  <Alert
                    key={idx}
                    {...alert}
                    onClose={() => {
                      alertManager.dismiss(idx);
                    }}
                  />
                ))}
              </div>
            </div>
          ) : null}

          <main className="w-full h-full">
            <Outlet />
          </main>
        </div>
        <Footer categories={props.categories}></Footer>
      </div>
    );
  }
);
