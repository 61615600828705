import { trpc } from 'shared/trpc';

export default function StorageViewer() {
  const query = trpc.files.useSWR(undefined, { refreshInterval: 10000 });
  return (
    <div>
      {Object.entries(query.data ?? {}).map(([type, val]) => (
        <div key={type}>
          {type}:
          {val.freeBytes ? (
            <div>
              Free: {(val.freeBytes / BigInt(1024 * 1024 * 1024)).toString()}GB
            </div>
          ) : null}
          {val.totalUsedBytes ? (
            <div>
              Total used storage:{' '}
              {(val.totalUsedBytes / BigInt(1024 * 1024)).toString()}MB
            </div>
          ) : null}
          {val.managedUsedBytes ? (
            <div>
              Archive images storage:{' '}
              {(val.managedUsedBytes / BigInt(1024 * 1024 * 1024)).toString()}GB
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
}
