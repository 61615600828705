import React from 'react';

interface AsProp<C extends React.ElementType> {
  as?: C;
}

type PropsToOmit<C extends React.ElementType, P> = keyof (AsProp<C> & P);

type PolymorphicComponentProp<
  C extends React.ElementType,
  Props = Record<string, any>
> = React.PropsWithChildren<Props & AsProp<C>> &
  Omit<React.ComponentPropsWithoutRef<C>, PropsToOmit<C, Props>>;

export default function Bar<C extends React.ElementType>(
  props: PolymorphicComponentProp<C, { className?: string }>
) {
  const { as, className, ...rest } = props;
  const Component = as ?? 'div';
  const passedClassname = className ?? '';
  return (
    <Component
      className={`overflow-hidden h-fit min-h-[2.5rem] bg-base-white rounded-lg border border-gray-300 divide-x flex flex-row items-stretch ${passedClassname}`}
      {...rest}
    >
      {React.Children.map(props.children, (child) => (
        <div className="flex items-stretch justify-stretch">{child}</div>
      ))}
    </Component>
  );
}
