import { useCallback, useState } from 'react';
import Button from './Button';
import UpIcon from 'assets/svg/tabler/chevron-up.svg';
import DownIcon from 'assets/svg/tabler/chevron-down.svg';
import { useClickAway } from 'client/hooks';

interface Props {
  leadingIcon?: React.FunctionComponent<{ className?: string }>;
  label: string;
}
export default function Dropdown(props: React.PropsWithChildren<Props>) {
  const [active, setActive] = useState(false);
  const clickAway = useCallback(() => {
    setActive(false);
  }, []);
  const ref = useClickAway<HTMLDivElement>(clickAway);
  return (
    <div ref={ref} className="relative z-10">
      <Button
        size="sm"
        hasColor={false}
        displayType="tertiary"
        leadingIcon={props.leadingIcon}
        trailingIcon={active ? UpIcon : DownIcon}
        label={props.label}
        onClick={() => {
          setActive(!active);
        }}
      />
      {active ? (
        <div className="absolute left-0 top-full pt-2">{props.children}</div>
      ) : null}
    </div>
  );
}
