import { cva } from 'class-variance-authority';
import Check from 'assets/svg/tabler/check.svg';
import Minus from 'assets/svg/tabler/minus.svg';

export interface OwnProps {
  inputType?: string;
  className?: string;
  size?: 'sm' | 'md';
  displayType?: 'box' | 'circle';
  checked: boolean;
  disabled?: boolean;
  onToggle: () => void;
}
export const checkBoxClasses = cva('relative inline-block shrink-0', {
  variants: {
    size: {
      sm: 'w-4 h-4 stroke-[1.67]',
      md: 'w-5 h-5 stroke-[2]',
    },
  },
});

export const inputClasses = cva(
  'peer enabled:hover:bg-primary-100 checked:bg-primary-50 border indeterminate:bg-primary-50 checked:border-primary-600 border indeterminate:border-primary-600 enabled:hover:border-primary-600 active:border-primary-600, disabled:cursor-default disabled:border-gray-200 disabled:pointer-events-none absolute w-full h-full whitespace-nowrap cursor-pointer select-none appearance-none bg-base-white border-1 border-gray-300',
  {
    variants: {
      displayType: {
        box: '',
        circle: 'checked:bg-primary-600 rounded-full',
      },
      size: {
        sm: '',
        md: '',
      },
    },
    compoundVariants: [
      { displayType: 'box', size: 'sm', className: 'rounded' },
      { displayType: 'box', size: 'md', className: 'rounded-md' },
    ],
  }
);

export const checkIconClasses = cva(
  [
    'hidden',
    'pointer-events-none',
    'absolute',
    'top-1/2',
    'left-1/2',
    '-translate-x-1/2',
    '-translate-y-1/2',
  ],
  {
    variants: {
      size: {
        sm: '',
        md: '',
      },
      displayType: {
        box: '',
        circle: '',
      },
    },
    compoundVariants: [
      {
        size: 'sm',
        displayType: 'box',
        className: 'w-3 h-3 text-primary-600',
      },
      {
        size: 'md',
        displayType: 'box',
        className: 'w-3.5 h-3.5 text-primary-600',
      },
      {
        size: 'sm',
        displayType: 'circle',
        className: 'w-2.5 h-2.5 text-base-white',
      },
      {
        size: 'md',
        displayType: 'circle',
        className: 'w-3.5 h-3.5 text-base-white',
      },
    ],
  }
);

export default function CheckBox(allProps: OwnProps) {
  let { inputType, className, size, displayType, onToggle, checked, disabled } =
    allProps;
  if (!className) {
    className = '';
  }
  if (!size) {
    size = 'md';
  }
  if (!displayType) {
    displayType = 'box';
  }
  if (!inputType) {
    inputType = 'checkbox';
  }
  const cvaProps = { size, displayType };
  return (
    <label className={`${className} ${checkBoxClasses(cvaProps)}`}>
      <input
        type={inputType}
        className={inputClasses(cvaProps)}
        checked={checked}
        onChange={onToggle}
        onClick={(e) => {
          e.stopPropagation();
        }}
        disabled={disabled}
      />
      <Check className={`peer-checked:block ${checkIconClasses(cvaProps)}`} />
      <Minus
        className={`peer-indeterminate:block ${checkIconClasses(cvaProps)}`}
      />
    </label>
  );
}
