import { action, computed, makeObservable, observable } from 'mobx';
import { createContext } from 'react';

export type AlertType = 'warning' | 'error' | 'notice' | 'success';
export interface AlertData {
  type: AlertType;
  message: string;
  details: string;
}

export class AlertManager {
  alerts: AlertData[];
  constructor(alerts: AlertData[]) {
    makeObservable(this, {
      alerts: observable,
      dismiss: action,
      dismissAll: action,
      addAlert: action,
      hasAlerts: computed,
    });
    this.alerts = [...alerts];
  }

  addAlert(alert: AlertData) {
    this.alerts.push(alert);
  }

  dismiss(idx: number) {
    this.alerts.splice(idx, 1);
  }

  dismissAll() {
    this.alerts = [];
  }

  get hasAlerts() {
    return this.alerts.length !== 0;
  }
}
// TODO: this default value is nonsense
export const AlertManagerContext = createContext<AlertManager>(
  new AlertManager([])
);
