import { usePreventScroll } from 'client/hooks';
import type { PropsWithChildren } from 'react';

export default function Modal(
  props: PropsWithChildren<{ classname?: string }>
) {
  const classname = props.classname ?? '';
  usePreventScroll();
  return (
    <div className="bg-[rgba(52,_64,_84,_0.4)] max-h-[100dvh] max-w-[100dvw] flex backdrop-blur-sm overscroll-contain z-50 fixed top-0 left-0 right-0 bottom-0 overflow-y-auto fade-in p-4 lg:p-12">
      <div
        className={`bg-base-white relative overflow-hidden rounded-xl max-h-full max-w-full flex flex-row p-6 gap-8 m-auto ${classname}`}
      >
        {props.children}
      </div>
    </div>
  );
}
