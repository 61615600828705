import { useState, useEffect } from 'react';
import { client, trpc } from 'shared/trpc';
import TagPicker from './TagPicker';
import { useDebounced } from 'shared/utils';

// Cache is not in sync on initial load, we want to do cache fetch only on initial mount
// Outside interface is just ids in ids out
// On render -> check if anything is missing in cache, set missing derived state?
import {
  globalTagCache,
  subscribe,
  unsubscribe,
  updateCache,
} from 'shared/tagCache';

export default function AddTagPicker(props: {
  label?: string;
  tagIds: number[];
  textArea?: boolean;
  setTagIds: (tags: number[]) => void;
}) {
  const [tagSearchKey, setTagSearchKey] = useState('');
  const [tagSearchKeyDebounced, setTagSearchKeyDebounced] = useDebounced(
    tagSearchKey,
    500
  );
  const [tagCache, setTagCache] = useState(globalTagCache);
  useEffect(() => {
    subscribe(setTagCache);
    return () => {
      unsubscribe(setTagCache);
    };
  }, []);

  const tagList = props.tagIds.map((id) => {
    return { id, name: tagCache[id] };
  });
  const missingIds = tagList
    .filter((e) => e.name === undefined)
    .map((e) => e.id);
  const missing = missingIds.length !== 0;

  const queryTags = trpc.queryTags.useSWR(missingIds, {
    isDisabled: !missing,
    onSuccess: (tags) => {
      updateCache(tags);
    },
  });

  const searchResult = trpc.filterTags.useSWR(tagSearchKeyDebounced, {
    keepPreviousData: true,
    isDisabled: tagSearchKeyDebounced === '',
    onSuccess: (tags) => {
      updateCache(tags);
    },
    onError: (err) => {
      console.log(JSON.stringify(err, null, 2));
    },
  });
  const hasExactMatch =
    searchResult.data?.some((el) => el.name === tagSearchKey) ?? false;
  const dedupedTags =
    tagSearchKey === ''
      ? []
      : searchResult.isLoading || tagSearchKey !== tagSearchKeyDebounced
      ? 'loading'
      : searchResult.data
          ?.filter((tag) => !props.tagIds.includes(tag.id))
          .concat(
            hasExactMatch ? [] : [{ id: -1, name: `Create ${tagSearchKey}` }]
          ) ?? 'loading';

  function tagDeleted(id: number) {
    props.setTagIds(props.tagIds.filter((el) => el !== id));
  }

  async function tagAdded(id: number) {
    if (id === -1) {
      // create the new tag
      // Add new tag
      id = await client.createTags.mutate(tagSearchKey);
      void searchResult.mutate();
    }
    props.setTagIds([...props.tagIds, id]);
  }

  function searchUpdated(e: string) {
    setTagSearchKey(e);
    if (e === '') {
      // do not debounce disabled search
      setTagSearchKeyDebounced(e);
    }
  }
  return (
    <TagPicker
      searchUpdated={searchUpdated}
      label={props.label}
      tagAdded={tagAdded}
      tagDeleted={tagDeleted}
      pickOptions={dedupedTags}
      textArea={props.textArea ?? false}
      selectedTags={tagList.map((tag) => {
        return { id: tag.id, name: tag.name || 'Loading...' };
      })}
    />
  );
}
