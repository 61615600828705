import Inspector from './Inspector';
import TabBar from './TabBar';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  type ArchiveCollection,
  type ArchiveCollectionEditor,
  type Query,
  type State,
  type Selection,
} from './CollectionManager';
import { type inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from 'shared/api';
import {
  distanceInMeter,
  equal,
  ignorePromise,
  toIsoDate,
  useUniqueKey,
} from 'shared/utils';
import { client } from 'shared/trpc';
import Button from './Button';
import { UpdateLocationSchema } from 'shared/validators';
import { type z } from 'zod';
import LocationPicker from './LocationPicker';
import AddTagPicker from './AddTagPicker';
import MapPin from 'assets/svg/tabler/map-pin.svg';
import EmptyEditorContent from './EmptyEditorContent';
import TrashIcon from 'assets/svg/tabler/trash.svg';
import ErrorTextInput from './ErrorTextInput';

type LocationItem = inferRouterOutputs<AppRouter>['queryLocations'][number];

// force date to be non null
// TODO: make generic so we do not export
export type LocationEdit = z.infer<typeof UpdateLocationSchema>;

const tabs = [
  ['basic', 'Basic Info'],
  ['tags', 'Tags'],
  ['advanced', 'Advanced'],
] as const;
type selections = (typeof tabs)[number][0];

const TestViewer = observer(
  (props: {
    item: LocationItem;
    editor: ArchiveCollectionEditor<LocationItem, LocationEdit>;
  }) => {
    return (
      <>
        <div className="flex flex-col h-full w-full">
          <div className="bg-gradient-to-r from-gray-600 to-gray-500 grow flex items-center justify-center">
            <MapPin className="text-base-white w-12 h-12" />
          </div>
          <div className="h-[3.375rem] flex flex-col px-4 justify-evenly">
            <div className="flex flex-row justify-between items-center">
              <div className="text-sm-light text-ellipsis whitespace-nowrap text-gray-500 overflow-hidden">
                {props.item.title}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

function stringPropEdited<T>(
  base: T,
  edit: Record<any, any>,
  stringProp: keyof T
) {
  if (edit[stringProp] === undefined) {
    return false;
  }
  return base[stringProp] !== edit[stringProp];
}

function anyStringPropEdited<T>(
  base: T,
  edit: Record<any, any>,
  props: Array<keyof T>
) {
  for (const prop of props) {
    if (stringPropEdited(base, edit, prop)) {
      return true;
    }
  }
  return false;
}

function locationEdited(base: LocationItem, edit: LocationEdit) {
  // TODO: loss of precision/mismatch errors after API/DB roundtrip
  if (edit.coordinates === undefined) {
    return false;
  }
  if (base.coordinates !== null && edit.coordinates !== null) {
    return distanceInMeter(base.coordinates, edit.coordinates) > 1;
  }
  return base.coordinates !== edit.coordinates;
}

function tagsEdited(oldTags: number[], newTags?: number[]) {
  if (newTags === undefined) {
    return false;
  }
  return !equal(new Set(oldTags), new Set(newTags));
}

function isEdited(base: LocationItem, edits: LocationEdit) {
  let edited = anyStringPropEdited(base, edits, ['title']);
  edited ||= anyStringPropEdited(base.metaData, edits, ['adminNotes']);
  edited ||= locationEdited(base, edits);
  edited ||= tagsEdited(
    base.locationTags.map((el) => el.tagId),
    edits.tags
  );
  return edited;
}

const Editor = observer(
  (props: {
    selection: Selection<LocationItem, LocationEdit>;
    errors: { formErrors: string[]; fieldErrors: Map<string, string[]> };
    currentTab: selections;
    updateEdit: (edit: LocationEdit) => void;
  }) => {
    useEffect(() => {
      console.log('mounted');
      console.log(`edit: ${JSON.stringify(props.selection)}`);
      return () => {
        console.log('unmounted');
      };
    }, []);
    if (!props.selection) {
      return <EmptyEditorContent />;
    }
    const currentTab = props.currentTab;
    const editType = props.selection.type;
    const edit = props.selection.edit;
    const base =
      editType === 'single' ? props.selection.selected[0] : undefined;
    console.warn(base?.coordinates);
    console.error(edit.coordinates);
    const title = edit.title ?? base?.title ?? '';
    const adminNotes = edit.adminNotes ?? base?.metaData.adminNotes ?? '';
    const tagIds =
      edit.tags ?? base?.locationTags.map((tag) => tag.tagId) ?? [];
    let location: typeof edit.coordinates;
    if (edit.coordinates) {
      location = {
        ...edit.coordinates,
        googlePlace: edit.coordinates?.googlePlace,
      };
    } else if (edit.coordinates === null) {
      location = undefined;
    } else {
      location = base?.coordinates;
    }
    console.error(location);

    if (currentTab === 'advanced') {
      if (base === undefined) {
        return null;
      }
      return (
        <>
          <div className="flex flex-row justify-between">
            <div className="text-sm-medium text-gray-700">Identifier #</div>
            <div className="text-sm-light text-gray-500">{base.id}</div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="text-sm-medium text-gray-700">Date Added</div>
            <div className="text-sm-light text-gray-500">
              {toIsoDate(base.metaData.createdAt) ?? ''}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="text-sm-medium text-gray-700">Contributor</div>
            <div className="text-sm-light text-gray-500">
              {base.metaData.creator.name}
            </div>
          </div>
          <ErrorTextInput
            errors={props.errors.fieldErrors.get('adminNotes')}
            placeholder="Notes"
            name="Admin Notes"
            label="Admin Notes"
            type="textarea"
            value={adminNotes}
            setValue={(val) => {
              const newEdit = {
                ...edit,
                adminNotes: val,
              };
              props.updateEdit(newEdit);
            }}
          />
        </>
      );
    }
    if (currentTab === 'basic') {
      if (editType !== 'single') {
        return null;
      }
      return (
        <>
          <ErrorTextInput
            errors={props.errors.fieldErrors.get('title')}
            placeholder="Title"
            name="Title for Location"
            label="Title for Location"
            type="text"
            value={title}
            setValue={(val) => {
              const newEdit = {
                ...edit,
                title: val,
              };
              props.updateEdit(newEdit);
            }}
          />
          <LocationPicker
            initialLocation={location ?? undefined}
            locationPicked={(loc) => {
              props.updateEdit({ ...edit, coordinates: loc });
            }}
          />
        </>
      );
    }
    if (currentTab === 'tags') {
      return (
        <AddTagPicker
          textArea
          tagIds={tagIds}
          setTagIds={(newTagIds) => {
            props.updateEdit({
              ...edit,
              tags: newTagIds,
            });
          }}
        />
      );
    }
  }
);

const TestInspector = observer(
  (props: {
    editor: ArchiveCollectionEditor<LocationItem, LocationEdit>;
    update: (state: 'loading' | 'done') => void;
    state: State;
  }) => {
    useEffect(() => {
      console.log('inspector mounted');
      return () => {
        console.log('inspector unmounted');
      };
    }, []);
    const [currentTab, setCurrentTab] = useState<selections>('basic');
    const editor = props.editor;
    const edited = editor.edits.isEdited;
    const [inspectorKey, updateInspector] = useUniqueKey(
      editor.edits.stringKey
    );

    const Picker = () => {
      return (
        <TabBar
          options={tabs}
          selected={currentTab}
          selectionUpdated={(el) => {
            setCurrentTab(el);
          }}
        />
      );
    };
    const Header = () => {
      return (
        <>
          <div className="pt-2 flex flex-row justify-between">
            <div className="text-xl-medium text-gray-900">Content Editor</div>
            <Button
              size="sm"
              displayType="secondary"
              hasColor={false}
              leadingIcon={TrashIcon}
              onClick={() => {
                void deleteSelection();
              }}
            ></Button>
          </div>
          <Picker />
        </>
      );
    };

    async function deleteSelection() {
      const selection = editor.edits.selection?.selected;
      if (selection === undefined || selection.length === 0) {
        return;
      }
      props.update('loading');
      editor.edits.updateEdit(editor.collectionType.createEmptyEdit());
      await client.deleteLocations.mutate(selection.map((s) => s.id));
      props.update('done');
      updateInspector();
    }

    async function applyChanges() {
      if (editor.edits.selection?.edit === undefined) {
        return;
      }
      props.update('loading');
      const result = await client.updateLocations.mutate({
        ids: editor.edits.selection.selected.map((el) => el.id),
        changes: editor.edits.selection.edit,
      });
      props.editor.setSubmissionErrors(result.data);
      if (!result.error && editor.edits.selection.type === 'single') {
        // commit edit
        editor.edits.updateEdit(editor.collectionType.createEmptyEdit());
      }
      props.update('done');
      updateInspector();
    }
    const [testSelection, setTestSelection] = useState<number[]>([]);

    return (
      <Inspector
        header={<Header />}
        key={inspectorKey}
        content={
          <div className="flex flex-col gap-6 grow">
            <Editor
              currentTab={currentTab}
              selection={editor.edits.selection}
              errors={editor.errors}
              updateEdit={(edit) => {
                editor.setSubmissionErrors();
                editor.edits.updateEdit(edit);
              }}
            ></Editor>
          </div>
        }
        footer={
          <>
            <div className="flex flex-row gap-2 items-center text-sm-light text-gray-500">
              <div
                className={`w-2 h-2 rounded-full shrink-0 ${
                  edited ? 'bg-warning-500' : 'bg-success-500'
                }`}
              ></div>
              <span>{edited ? 'Edited' : 'Not edited'}</span>
            </div>
            <div className="flex flex-row gap-3">
              <Button
                size="md"
                displayType="secondary"
                hasColor={false}
                label="Cancel"
                stretch="horizontal"
                onClick={() => {
                  editor.edits.updateEdit(
                    editor.collectionType.createEmptyEdit()
                  );
                  updateInspector();
                }}
              />
              <Button
                size="md"
                displayType="primary"
                label="Apply"
                stretch="horizontal"
                disabled={!editor.isValid || props.state !== 'idle'}
                onClick={ignorePromise(applyChanges)}
              />
            </div>
          </>
        }
      />
    );
  }
);

// ArchiveCollection<TestItem, TestEdit>
export const LocationCollection: ArchiveCollection<LocationItem, LocationEdit> =
  {
    createEmptyEdit: function (): LocationEdit {
      return {};
    },
    createMultiEdit: function (
      base: LocationItem,
      edits: LocationEdit
    ): LocationEdit {
      return this.createEmptyEdit();
    },
    query: async function (q: Query): Promise<LocationItem[]> {
      const result = await client.queryLocations.query({
        query: q.search,
        tags: q.tags,
        order: q.order,
      });
      console.log(result);
      return result;
    },
    inspector: TestInspector,
    itemViewer: TestViewer,
    isEdited,

    resource: 'location',

    validateEdits: function (edits: LocationEdit) {
      const validated = UpdateLocationSchema.safeParse(edits);
      if (validated.success) {
        return { fieldErrors: new Map(), formErrors: [] };
      }
      const error = validated.error.flatten();
      return {
        formErrors: error.formErrors,
        fieldErrors: new Map(Object.entries(error.fieldErrors)),
      };
    },
  };
