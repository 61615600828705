export default function Inspector(props: {
  header: React.ReactNode;
  content: React.ReactNode;
  footer: React.ReactNode;
}) {
  return (
    <div className="basis-[34rem] shrink border border-gray-200 rounded-[0.625rem] flex flex-col lg:min-h-[60rem] bg-base-white">
      <div className="px-8 py-4 flex flex-col gap-6 border-b border-gray-200">
        {props.header}
      </div>
      <div className="grow flex flex-col px-8 py-6 border-b border-gray-200">
        {props.content}
      </div>
      <div className="px-8 py-4 flex flex-row justify-between items-center gap-3">
        {props.footer}
      </div>
    </div>
  );
}
