import TextInput from './TextInput';
import X from 'assets/svg/tabler/x.svg';
import React, { useState, useEffect, useRef } from 'react';
import { clamp } from 'shared/utils';

export function TagSelectionContainer(
  props: React.PropsWithChildren<Record<string, unknown>>
) {
  return (
    <div className="group text-md-light z-50 text-gray-900 absolute rounded-lg shadow-lg top-full left-0 right-0 border border-gray-100 bg-base-white flex flex-col items-stretch">
      {props.children}
    </div>
  );
}

export function TagSelectionItem(props: {
  focus: boolean;
  onMouseEnter?: () => void;
  onClick?: () => void;
  name: string;
}) {
  return (
    <div
      className={`px-3.5 py-2.5 ${props.focus ? 'bg-gray-50' : ''}`}
      onMouseEnter={props.onMouseEnter}
      onClick={props.onClick}
    >
      {props.name}
    </div>
  );
}

export function TagSelectionLoading(props: Record<string, unknown>) {
  return (
    <TagSelectionContainer>
      <TagSelectionItem focus={true} name="Loading..." />
    </TagSelectionContainer>
  );
}
export function TagSelection<T extends React.Key | null>(props: {
  pickOptions: Array<{ id: T; name: string }>;
  initialFocus: number;
  tagSelected: (tag: { id: T; name: string }) => void;
}) {
  const [focus, setFocus] = useState(props.initialFocus);
  const { tagSelected, pickOptions } = props;

  // TODO: fix this later, focus etc
  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      if (e.code === 'ArrowUp') {
        setFocus(clamp(focus - 1, 0, pickOptions.length - 1));
        e.preventDefault();
      } else if (e.code === 'ArrowDown') {
        setFocus(clamp(focus + 1, 0, pickOptions.length - 1));
        e.preventDefault();
      } else if (e.code === 'Enter') {
        tagSelected(pickOptions[focus]);
        e.preventDefault();
      }
    }
    window.addEventListener('keydown', onKeyDown, false);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [tagSelected, pickOptions, focus]);
  return (
    <TagSelectionContainer>
      {props.pickOptions.map((option, idx) => (
        <TagSelectionItem
          key={option.id}
          name={option.name}
          focus={idx === focus}
          onMouseEnter={() => {
            setFocus(idx);
          }}
          onClick={() => {
            props.tagSelected(option);
          }}
        />
      ))}
    </TagSelectionContainer>
  );
}

interface PropType<T extends React.Key | null> {
  label?: string;
  leadingIcon?: React.FunctionComponent<{ className?: string }>;
  textArea: boolean;
  clearSearch?: boolean;
  pickOptions: Array<{ id: T; name: string }> | 'loading';
  selectedTags: Array<{ id: T; name: string }>;
  searchUpdated: (search: string) => void;
  tagAdded: (id: T) => void;
  tagDeleted: (id: T) => void;
}

export function TagSearch<T extends React.Key | null>(props: {
  pickOptions: Array<{ id: T; name: string }> | 'loading';
  clearSearch: boolean;
  searchUpdated: (search: string) => void;
  tagSelected: (tag: { id: T; name: string }) => void;
  lastTagDeleted: () => void;
}) {
  const inputField = useRef<HTMLInputElement>(null);
  function inputChanged(e: React.ChangeEvent<HTMLInputElement>) {
    props.searchUpdated(e.target.value);
  }
  function backspaceHandler(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.code === 'Backspace' && e.currentTarget.value === '') {
      props.lastTagDeleted();
    }
  }
  function selected(e: { id: T; name: string }) {
    if (inputField.current) {
      if (props.clearSearch) {
        inputField.current.value = '';
      }
    }
    props.tagSelected(e);
    if (props.clearSearch) {
      props.searchUpdated('');
    }
  }
  let selection = null;
  if (props.pickOptions === 'loading') {
    selection = <TagSelectionLoading />;
  } else if (props.pickOptions.length > 0) {
    selection = (
      <TagSelection
        pickOptions={props.pickOptions}
        initialFocus={0}
        tagSelected={selected}
      />
    );
  }
  return (
    <div className="flex-1">
      <input
        ref={inputField}
        type="text"
        className="apperance-none outline-none resize-none w-full"
        onKeyDown={backspaceHandler}
        onChange={inputChanged}
      />
      {selection}
    </div>
  );
}
export default function TagPicker<T extends React.Key | null>(
  props: PropType<T>
) {
  const clearSearch = props.clearSearch ?? true;
  function handleDelete(tag: { id: T }) {
    props.tagDeleted(tag.id);
  }

  return (
    <div>
      <TextInput
        type="faux"
        leadingIcon={props.leadingIcon}
        className="relative"
        label={props.label}
      >
        <div className={props.textArea ? 'min-h-[100px]' : ''}>
          <div className="flex flex-row items-start gap-1 flex-wrap">
            {props.selectedTags.map((tag) => (
              <div
                key={tag.id}
                className="pl-2 pr-1 rounded-md border border-gray-300 flex flex-row items-center text-sm-medium text-gray-700"
              >
                {tag.name}
                <X
                  className="w-[14px] h-[14px] p-[2px] text-gray-300 cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDelete(tag);
                  }}
                />
              </div>
            ))}
            <TagSearch
              clearSearch={clearSearch}
              searchUpdated={props.searchUpdated}
              tagSelected={(tag) => {
                props.tagAdded(tag.id);
              }}
              pickOptions={props.pickOptions}
              lastTagDeleted={() => {
                if (props.selectedTags.length > 0) {
                  handleDelete(
                    props.selectedTags[props.selectedTags.length - 1]
                  );
                }
              }}
            />
          </div>
        </div>
      </TextInput>
    </div>
  );
}
