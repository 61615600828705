import { useReducer, useRef, useState, useEffect } from 'react';

export async function delay(ms: number) {
  return await new Promise(function (resolve) {
    setTimeout(resolve, ms);
  });
}
export function clamp(val: number, min: number, max: number): number {
  return val > max ? max : val < min ? min : val;
}

export function objToMap<T>(obj: Record<string, T>) {
  return new Map(Object.entries(obj));
}
export function ignorePromise<T extends (...args: any[]) => Promise<any>>(
  fn: T
): (...args: Parameters<T>) => void {
  return (...args: Parameters<T>): void => {
    void fn(...args);
  };
}

export function useForceUpdate(): () => void {
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);
  return forceUpdate;
}

export function useUniqueKey(base: string | number): [string, () => void] {
  const [counter, setCounter] = useState(0);
  return [
    `${base}_${counter}`,
    () => {
      setCounter((c) => c + 1);
    },
  ];
}

export function csrfHeader() {
  const csrfCookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith('_csrf_token'))
    ?.split('=')[1];
  if (!csrfCookie) {
    return undefined;
  }
  return { 'X-Csrf-Token': csrfCookie };
}

export function versionHeader() {
  return { 'X-Version': BUILD_VERSION };
}

export function allHeaders() {
  return { ...csrfHeader(), ...versionHeader() };
}

export async function fetchWrapper(
  path: RequestInfo | URL,
  config?: RequestInit
) {
  const headers = { ...config?.headers, ...allHeaders() };
  const request = new Request(path, { ...config, headers });
  const result = await fetch(request);
  if (result.status === 412) {
    // Server tells us we have an outdated version, reload to the newest version
    console.log('reloading');
    window.location.reload();
  }
  return result;
}

export function useConstant<T>(c: (() => T) | T): T {
  const constantRef = useRef<{ box: T }>();
  // We need to guard against the function return being the same value that we compare the ref against
  // Box it up so ref being falsy means we have not inited yet
  if (constantRef.current == null) {
    constantRef.current = { box: c instanceof Function ? c() : c };
  }
  return constantRef.current.box;
}

export function toIsoDate(date: Date): string {
  return date.toISOString().split('T', 1)[0];
}

export function useDebounced<T>(
  value: T,
  debounceMs: number
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const debouncedState = useState(value);
  const [debouncedValue, setDebouncedValue] = debouncedState;
  useEffect(() => {
    const shouldDebounce = value !== debouncedValue;
    if (shouldDebounce) {
      const timeout = setTimeout(() => {
        // commit value after it has not changed for debounceMs
        setDebouncedValue(value);
      }, debounceMs);
      return () => {
        clearTimeout(timeout);
      };
    } else {
      return undefined;
    }
  }, [debounceMs, debouncedValue, setDebouncedValue, value]);
  return debouncedState;
}

export function useKvCache<K, V>(
  keysOfInterest: K[],
  fetch: (keys: K[]) => Promise<Array<[K, V]>>
) {
  const [resolved, setResolved] = useState(new Map<K, V>());
  const [inflight, setInflight] = useState(false);
  const activeFetcher = useRef(fetch);
  // If fetcher changes we want to invalidate the cache and refetch
  // Careful: inflight fetcher should terminate with NOOPs
  // If missingKeys change we want to wait for the next fetch to finish and then fetch if keys are still missing
  // So basically a noop.
  if (fetch !== activeFetcher.current) {
    activeFetcher.current = fetch;
    setInflight(false);
    setResolved(new Map());
  }

  const missingKeys = keysOfInterest.filter((k) => !resolved.has(k));
  if (missingKeys.length !== 0 && !inflight) {
    setInflight(true);
    let retryCount = 0;
    const retries = 3;
    const tryFetch = () => {
      fetch(missingKeys).then(
        (result) => {
          if (fetch !== activeFetcher.current) {
            return;
          }
          const newMap = new Map(resolved);
          for (const [k, v] of result) {
            newMap.set(k, v);
          }
          setResolved(newMap);
          setInflight(false);
        },
        (_) => {
          console.error(`Fetch errror, retry: ${retryCount}/${retries}`);
          if (retryCount !== retries) {
            retryCount++;
            void delay(1000).then(tryFetch);
          }
        }
      );
    };
    tryFetch();
  }
  return resolved;
}

export function intersection<T>(a: Iterable<T>, b: Iterable<T>): Set<T> {
  const result = new Set<T>();
  let baseSet;
  let compareIterable;
  if (a instanceof Set && b instanceof Set) {
    if (a.size < b.size) {
      compareIterable = a;
      baseSet = b;
    } else {
      compareIterable = b;
      baseSet = a;
    }
  } else if (a instanceof Set) {
    baseSet = a;
    compareIterable = b;
  } else if (b instanceof Set) {
    baseSet = b;
    compareIterable = a;
  } else {
    baseSet = new Set(a);
    compareIterable = b;
  }

  for (const el of compareIterable) {
    if (baseSet.has(el)) {
      result.add(el);
    }
  }
  return result;
}

export function equal<T>(a: Set<T>, b: Set<T>): boolean {
  if (a.size !== b.size) {
    return false;
  }
  for (const el of a) {
    if (!b.has(el)) {
      return false;
    }
  }
  return true;
}

type ShallowCompareArray = readonly any[] | null | undefined;
export function shallowEqualArrays(
  a: ShallowCompareArray,
  b: ShallowCompareArray
): boolean {
  if (Object.is(a, b)) {
    return true;
  }
  if (a == null || b == null) {
    return false;
  }
  const length = a.length;
  if (length !== b.length) {
    return false;
  }
  for (let i = 0; i < length; i++) {
    if (!Object.is(b[i], a[i])) {
      return false;
    }
  }
  return true;
}

export function distanceInMeter(
  p1: { lat: number; lng: number },
  p2: { lat: number; lng: number }
) {
  const lat1 = p1.lat;
  const lon1 = p1.lng;
  const lat2 = p2.lat;
  const lon2 = p2.lng;

  // https://www.movable-type.co.uk/scripts/latlong.html
  const R = 6371e3; // metres
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c; // in metres

  return d;
}

export type StringLiteral<T> = T extends string
  ? string extends T
    ? never
    : T
  : never;
export type NumberLiteral<T> = T extends number
  ? number extends T
    ? never
    : T
  : never;
export type BooleanLiteral<T> = T extends number
  ? number extends T
    ? never
    : T
  : never;
export type Literal<T> =
  | StringLiteral<T>
  | NumberLiteral<T>
  | BooleanLiteral<T>;
