import Inspector from './Inspector';
import TabBar from './TabBar';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  type ArchiveCollection,
  type ArchiveCollectionEditor,
  type Query,
  type State,
  type Selection,
} from './CollectionManager';
import { type inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from 'shared/api';
import { equal, ignorePromise, toIsoDate, useUniqueKey } from 'shared/utils';
import { client } from 'shared/trpc';
import Button, { ReactRouterLinkButton } from './Button';
import { UpdateArchiveImageSchema } from 'shared/validators';
import { z } from 'zod';

import AddTagPicker from './AddTagPicker';
import EmptyEditorContent from './EmptyEditorContent';
import TrashIcon from 'assets/svg/tabler/trash.svg';
import AddLocationPicker from './AddLocationPicker';
import ValidatingTextInput, {
  DateSerializer,
  zodSerializer,
} from './ValidatingTextInput';
import ErrorTextInput from './ErrorTextInput';
import { useQueryParam } from '../hooks';
import { useSearchParams } from 'react-router-dom';

type ArchiveImageItem =
  inferRouterOutputs<AppRouter>['queryArchiveImages'][number];

// force date to be non null
// TODO: make generic so we do not export
export type ArchiveImageEdit = z.infer<typeof UpdateArchiveImageSchema>;

const tabs = [
  ['basic', 'Basic Info'],
  ['tags', 'Tags'],
  ['advanced', 'Advanced'],
] as const;
type selections = (typeof tabs)[number][0];

const TestViewer = observer(
  (props: {
    item: ArchiveImageItem;
    editor: ArchiveCollectionEditor<ArchiveImageItem, ArchiveImageEdit>;
  }) => {
    return (
      <>
        <img
          className="h-0 w-full grow object-cover"
          src={props.item.url}
        ></img>
        <div className="h-[3.375rem] flex flex-col px-4 justify-evenly">
          <div className="flex flex-row justify-between items-center">
            <div className="text-sm-light text-ellipsis whitespace-nowrap text-gray-500 overflow-hidden">
              {props.item.title ||
                `${props.item.file.file.originalFilename}.${props.item.file.fileExt}`}
            </div>
            <div className="w-2.5 h-2.5 rounded-full shrink-0 bg-error-500"></div>
          </div>
        </div>
      </>
    );
  }
);

function stringPropEdited<T>(
  base: T,
  edit: Record<any, any>,
  stringProp: keyof T
) {
  if (edit[stringProp] === undefined) {
    return false;
  }
  return base[stringProp] !== edit[stringProp];
}

function anyStringPropEdited<T>(
  base: T,
  edit: Record<any, any>,
  props: Array<keyof T>
) {
  for (const prop of props) {
    if (stringPropEdited(base, edit, prop)) {
      return true;
    }
  }
  return false;
}

function tagsEdited(oldTags: number[], newTags?: number[]) {
  if (newTags === undefined) {
    return false;
  }
  return !equal(new Set(oldTags), new Set(newTags));
}

function dateEdited(oldDate: Date | null, newDate?: Date | null) {
  if (newDate === undefined) {
    return false;
  }
  return oldDate?.getTime() !== newDate?.getTime();
}
function isEdited(base: ArchiveImageItem, edits: ArchiveImageEdit) {
  let edited = anyStringPropEdited(base, edits, ['title', 'description']);
  edited ||= anyStringPropEdited(base.metaData, edits, ['adminNotes']);
  edited ||= tagsEdited(
    base.imageTags.map((el) => el.tagId),
    edits.tags
  );
  edited ||= tagsEdited(
    base.locations.map((el) => el.id),
    edits.locations
  );
  edited ||= dateEdited(base.imageCreatedAt, edits.imageCreatedAt);
  return edited;
}

const Editor = observer(
  (props: {
    selection: Selection<ArchiveImageItem, ArchiveImageEdit>;
    errors: { formErrors: string[]; fieldErrors: Map<string, string[]> };
    currentTab: selections;
    updateEdit: (edit: ArchiveImageEdit) => void;
  }) => {
    useEffect(() => {
      console.log('mounted');
      console.log(`edit: ${JSON.stringify(props.selection)}`);
      return () => {
        console.log('unmounted');
      };
    }, []);
    if (!props.selection) {
      return <EmptyEditorContent />;
    }
    const [searchParams, _] = useSearchParams();
    const currentTab = props.currentTab;
    const editType = props.selection.type;
    const edit = props.selection.edit;
    const base =
      editType === 'single' ? props.selection.selected[0] : undefined;
    const title = edit.title ?? base?.title ?? '';
    const adminNotes = edit.adminNotes ?? base?.metaData.adminNotes ?? '';
    const tagIds = edit.tags ?? base?.imageTags.map((tag) => tag.tagId) ?? [];
    const locationIds =
      edit.locations ?? base?.locations.map((l) => l.id) ?? [];
    const imageCreatedAt = edit.imageCreatedAt ?? base?.imageCreatedAt ?? null;
    const description = edit.description ?? base?.description ?? '';

    if (currentTab === 'advanced') {
      if (base === undefined) {
        return null;
      }
      const activeBatch = searchParams.get('batch');
      let batchLink;
      if (activeBatch === base.uploadBatch.id.toString()) {
        // Keep old query parmeters (filters etc.) but remove the batch filter
        batchLink = new URLSearchParams(searchParams);
        batchLink.delete('batch');
      } else {
        // Reset url paramters (filters etc.), we want to just see all batch items
        batchLink = new URLSearchParams();
        batchLink.set('batch', base.uploadBatch.id.toString());
      }
      return (
        <>
          <div className="flex flex-row justify-between items-center">
            <div className="text-sm-medium text-gray-700">Identifier #</div>
            <div className="text-sm-light text-gray-500">{base.id}</div>
          </div>
          <div className="flex flex-row justify-between items-center">
            <div className="text-sm-medium text-gray-700">File Name</div>
            <div className="text-sm-light text-gray-500">
              {base.file.file.originalFilename}
            </div>
          </div>
          <div className="flex flex-row justify-between items-center">
            <div className="text-sm-medium text-gray-700">File Format</div>
            <div className="text-sm-light text-gray-500">
              {base.file.fileExt}
            </div>
          </div>
          <div className="flex flex-row justify-between items-center">
            <div className="text-sm-medium text-gray-700">Size</div>
            <div className="text-sm-light text-gray-500">
              {base.file.width}X{base.file.height}
            </div>
          </div>
          <div className="flex flex-row justify-between items-center">
            <div className="text-sm-medium text-gray-700">Date Added</div>
            <div className="text-sm-light text-gray-500">
              {toIsoDate(base.metaData.createdAt) ?? ''}
            </div>
          </div>
          <div className="flex flex-row justify-between items-center">
            <div className="text-sm-medium text-gray-700">Contributor</div>
            <div className="text-sm-light text-gray-500">
              {base.metaData.creator.name}
            </div>
          </div>
          <div className="flex flex-row justify-between items-center">
            <div className="text-sm-medium text-gray-700">Batch Title</div>
            <ReactRouterLinkButton
              displayType="secondary"
              size="sm"
              hasColor={false}
              to={{ search: batchLink.toString() }}
              label={base.uploadBatch.title}
            />
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-col gap-1">
              <div className="text-sm-medium text-gray-700">
                Batch Description
              </div>
              <div className="text-sm-light text-gray-500">
                {base.uploadBatch.description}
              </div>
            </div>
          </div>
          <ErrorTextInput
            errors={props.errors.fieldErrors.get('adminNotes')}
            placeholder="Notes"
            name="Admin Notes"
            label="Admin Notes"
            type="textarea"
            value={adminNotes}
            setValue={(val) => {
              const newEdit = {
                ...edit,
                adminNotes: val,
              };
              props.updateEdit(newEdit);
            }}
          />
        </>
      );
    }
    if (currentTab === 'basic') {
      if (editType !== 'single') {
        return null;
      }
      return (
        <>
          <ValidatingTextInput
            errors={props.errors.fieldErrors.get('title')}
            placeholder="Title"
            name="Title for Image"
            label="Title for Image"
            type="text"
            value={title}
            serializer={zodSerializer(z.string())}
            setValue={(val) => {
              const newEdit = {
                ...edit,
                title: val,
              };
              props.updateEdit(newEdit);
            }}
          ></ValidatingTextInput>
          <ValidatingTextInput
            errors={props.errors.fieldErrors.get('imageCreatedAt')}
            name="Date Created"
            label="Date Created"
            type="date"
            serializer={DateSerializer}
            value={imageCreatedAt}
            setValue={(val) => {
              const newEdit = {
                ...edit,
                imageCreatedAt: val,
              };

              props.updateEdit(newEdit);
            }}
          ></ValidatingTextInput>
          <ValidatingTextInput
            serializer={zodSerializer(z.string())}
            errors={props.errors.fieldErrors.get('description')}
            placeholder="Description"
            name="Description for Image"
            label="Description for Image"
            type="textarea"
            value={description}
            setValue={(val) => {
              const newEdit = {
                ...edit,
                description: val,
              };
              props.updateEdit(newEdit);
            }}
          ></ValidatingTextInput>
        </>
      );
    }
    if (currentTab === 'tags') {
      return (
        <>
          <div className="flex flex-col gap-1.5">
            <div>Tags</div>
            <AddTagPicker
              textArea
              tagIds={tagIds}
              setTagIds={(newTagIds) => {
                props.updateEdit({
                  ...edit,
                  tags: newTagIds,
                });
              }}
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <div>Locations</div>
            <AddLocationPicker
              textArea
              locationIds={locationIds}
              setLocationIds={(newLocationIds) => {
                props.updateEdit({
                  ...edit,
                  locations: newLocationIds,
                });
              }}
            />
          </div>
        </>
      );
    }
  }
);

const TestInspector = observer(
  (props: {
    editor: ArchiveCollectionEditor<ArchiveImageItem, ArchiveImageEdit>;
    update: (state: 'loading' | 'done') => void;
    state: State;
  }) => {
    useEffect(() => {
      console.log('inspector mounted');
      return () => {
        console.log('inspector unmounted');
      };
    }, []);
    const [currentTab, setCurrentTab] = useState<selections>('basic');
    const editor = props.editor;
    const edited = editor.edits.isEdited;
    const [inspectorKey, updateInspector] = useUniqueKey(
      editor.edits.stringKey
    );

    const Picker = () => {
      return (
        <TabBar
          options={tabs}
          selected={currentTab}
          selectionUpdated={(el) => {
            setCurrentTab(el);
          }}
        />
      );
    };
    const Header = () => {
      return (
        <>
          <div className="pt-2 flex flex-row justify-between">
            <div className="text-xl-medium text-gray-900">Content Editor</div>
            <Button
              size="sm"
              displayType="secondary"
              hasColor={false}
              leadingIcon={TrashIcon}
              onClick={() => {
                void deleteSelection();
              }}
            ></Button>
          </div>
          <Picker />
        </>
      );
    };

    async function deleteSelection() {
      const selection = editor.edits.selection?.selected;
      if (selection === undefined || selection.length === 0) {
        return;
      }
      props.update('loading');
      editor.edits.updateEdit(editor.collectionType.createEmptyEdit());
      await client.deleteArchiveImages.mutate(selection.map((s) => s.id));
      props.update('done');
      updateInspector();
    }

    async function applyChanges() {
      if (editor.edits.selection?.edit === undefined) {
        return;
      }
      props.update('loading');
      const result = await client.updateArchiveImages.mutate({
        ids: editor.edits.selection.selected.map((el) => el.id),
        changes: editor.edits.selection.edit,
      });
      props.editor.setSubmissionErrors(result.data);
      if (!result.error && editor.edits.selection.type === 'single') {
        // commit edit
        editor.edits.updateEdit(editor.collectionType.createEmptyEdit());
      }
      props.update('done');
      updateInspector();
    }

    return (
      <Inspector
        header={<Header />}
        key={inspectorKey}
        content={
          <div className="flex flex-col gap-4 grow">
            <Editor
              currentTab={currentTab}
              selection={editor.edits.selection}
              errors={editor.errors}
              updateEdit={(edit) => {
                editor.setSubmissionErrors();
                editor.edits.updateEdit(edit);
              }}
            ></Editor>
          </div>
        }
        footer={
          <>
            <div className="flex flex-row gap-2 items-center text-sm-light text-gray-500">
              <div
                className={`w-2 h-2 rounded-full shrink-0 ${
                  edited ? 'bg-warning-500' : 'bg-success-500'
                }`}
              ></div>
              <span>{edited ? 'Edited' : 'Not edited'}</span>
            </div>
            <div className="flex flex-row gap-3">
              <Button
                size="md"
                displayType="secondary"
                hasColor={false}
                label="Cancel"
                stretch="horizontal"
                onClick={() => {
                  editor.edits.updateEdit(
                    editor.collectionType.createEmptyEdit()
                  );
                  updateInspector();
                }}
              />
              <Button
                size="md"
                displayType="primary"
                label="Apply"
                stretch="horizontal"
                disabled={!editor.isValid || props.state !== 'idle'}
                onClick={ignorePromise(applyChanges)}
              />
            </div>
          </>
        }
      />
    );
  }
);

// ArchiveCollection<TestItem, TestEdit>
export const ArchiveImageCollection: ArchiveCollection<
  ArchiveImageItem,
  ArchiveImageEdit
> = {
  createEmptyEdit: function (): ArchiveImageEdit {
    return {};
  },
  createMultiEdit: function (
    _base: ArchiveImageItem,
    _edits: ArchiveImageEdit
  ): ArchiveImageEdit {
    return this.createEmptyEdit();
  },
  query: async function (q: Query): Promise<ArchiveImageItem[]> {
    const result = await client.queryArchiveImages.query({
      query: q.search,
      tags: q.tags,
      order: q.order,
      batch: q.uploadBatch,
    });
    console.log(result);
    return result;
  },
  inspector: TestInspector,
  itemViewer: TestViewer,
  isEdited,

  resource: 'archiveImage',

  validateEdits: function (edits: ArchiveImageEdit) {
    const validated = UpdateArchiveImageSchema.safeParse(edits);
    if (validated.success) {
      return { fieldErrors: new Map(), formErrors: [] };
    }
    const error = validated.error.flatten();
    return {
      formErrors: error.formErrors,
      fieldErrors: new Map(Object.entries(error.fieldErrors)),
    };
  },
};
