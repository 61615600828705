import { type Literal } from 'shared/utils';

interface Props<Id> {
  options: ReadonlyArray<readonly [Id, string]>;
  selected: Id;
  selectionUpdated: (selected: Id) => void;
}

export default function TabBar<Id>(props: Props<Literal<Id>>) {
  return (
    <div className="flex flex-row w-full border border-gray-100 bg-gray-50 rounded-lg p-1 gap-2">
      {props.options.map(([key, value]) => {
        const selected = props.selected === key;
        const selectedClass = selected ? 'selected' : '';
        return (
          <div
            onClick={() => {
              props.selectionUpdated(key);
            }}
            key={key}
            className={`${selectedClass} flex flex-1 justify-center items-center text-sm-medium text-gray-500 rounded-md p-2 cursor-pointer select-none selected:bg-base-white selected:text-gray-700 selected:shadow-sm`}
          >
            {value}
          </div>
        );
      })}
    </div>
  );
}
