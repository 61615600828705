import {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
  useLayoutEffect,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { shallowEqualArrays } from 'shared/utils';
import { type ZodEnum } from 'zod';

export function useQueryParam(
  key: string
): [readonly string[], (newValue: string[]) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const setQuery = useCallback(
    (value: string[]) => {
      setSearchParams((prev) => {
        // Do we need to copy prev or is it already copied?
        prev.delete(key);
        for (const val of value) {
          prev.append(key, val);
        }
        return prev;
      });
    },
    [setSearchParams, key]
  );
  const freshValues = Object.freeze(searchParams.getAll(key));
  const [value, setValue] = useState(freshValues);
  if (!shallowEqualArrays(value, freshValues)) {
    setValue(freshValues);
  }
  return [value, setQuery];
}
export function useQueryParamEnum<T extends [string, ...string[]]>(
  key: string,
  values: ZodEnum<T>,
  def: T[number]
): [T[number], (val: T[number]) => void] {
  const [query, setQuery] = useQueryParam(key);
  const set = (val: T[number]) => {
    setQuery([val]);
  };
  if (!(query[0] in values.Enum)) {
    return [def, set];
  }
  return [query[0], set];
}

export function useIntQueryParam(
  key: string
): [number | null, (newValue: number | null) => void] {
  const [param, setParam] = useQueryParam(key);
  const parsed = useMemo(() => {
    const result = parseInt(param[0] ?? '', 10);
    if (isNaN(result)) {
      return null;
    } else {
      return result;
    }
  }, [param]);
  const setParsed = useCallback(
    (value: number | null) => {
      if (value === null) {
        setParam([]);
      } else {
        setParam([value.toString(10)]);
      }
    },
    [setParam]
  );
  return [parsed, setParsed];
}

export function useClickAway<T extends HTMLElement>(cb: () => void) {
  const ref = useRef<T>(null);

  useEffect(() => {
    console.log('Running handler!!!');
    const handler = (e: MouseEvent | TouchEvent) => {
      const element = ref.current;
      if (element && !element.contains(e.target as Node)) {
        cb();
      }
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [cb]);

  return ref;
}

let preventScrollCount = 0;
let restore: () => void;

// Sets a CSS property on an element, and returns a function to revert it to the previous value.
function setStyle(element: HTMLElement, style: string, value: string) {
  const cur = element.style.getPropertyValue(style);
  const prio = element.style.getPropertyPriority(style);
  console.error(`${style}: ${value} ${prio}`);
  element.style.setProperty(style, value, prio);

  return () => {
    element.style.setProperty(style, cur, prio);
  };
}

// For most browsers, all we need to do is set `overflow: hidden` on the root element, and
// add some padding to prevent the page from shifting when the scrollbar is hidden.
function preventScrollStandard() {
  const restorePadding = setStyle(
    document.documentElement,
    'paddingRight',
    `${window.innerWidth - document.documentElement.clientWidth}px`
  );
  const restoreOverflow = setStyle(
    document.documentElement,
    'overflow',
    'hidden'
  );

  return () => {
    restoreOverflow();
    restorePadding();
  };
}

export function usePreventScroll(options: { isDisabled?: boolean } = {}) {
  const { isDisabled } = options;

  useLayoutEffect(() => {
    if (isDisabled) {
      return;
    }

    preventScrollCount++;
    if (preventScrollCount === 1) {
      restore = preventScrollStandard();
    }

    return () => {
      preventScrollCount--;
      if (preventScrollCount === 0) {
        restore();
      }
    };
  }, [isDisabled]);
}
type useRequestResult<T> =
  | { state: 'loading'; data?: undefined }
  | { state: 'done'; data: T }
  | { state: 'error'; data?: undefined }
  | { state: 'idle'; data?: undefined };
export function useRequest<T>(
  request: () => Promise<T>
): [useRequestResult<T>, () => void] {
  const [shouldRetry, setShouldRetry] = useState(false);
  const [requestState, setRequestState] = useState<useRequestResult<T>>({
    state: 'idle',
  });

  const makeRequest = useCallback(async () => {
    try {
      const result = await request();
      setRequestState({ state: 'done', data: result });
    } catch (e) {
      setRequestState({ state: 'error' });
    }
  }, [request]);

  if (requestState.state === 'idle') {
    void makeRequest();
    setRequestState({ state: 'loading' });
  }

  useEffect(() => {
    if (requestState.state === 'loading') {
      return;
    }
    if (shouldRetry) {
      setShouldRetry(false);
      void makeRequest();
      setRequestState({ state: 'loading' });
    }
  }, [shouldRetry, requestState, setShouldRetry, makeRequest]);

  return [
    requestState as useRequestResult<T>,
    () => {
      setShouldRetry(true);
    },
  ];
}
