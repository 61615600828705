import LeftIcon from 'assets/svg/tabler/arrow-left.svg';
import RightIcon from 'assets/svg/tabler/arrow-right.svg';
import { ReactRouterLinkButton } from './Button';
import { Link, useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';

function navLinkStyle(isActive: boolean) {
  return `flex justify-center hover:bg-gray-50 rounded-lg items-center text-sm-medium p-3 min-w-[2.5rem] h-10 ${
    isActive ? 'text-gray-800 bg-gray-50' : 'text-gray-500'
  }`;
}

function sequence(start: number, numElements: number) {
  const result: number[] = new Array(numElements);
  for (let i = 0; i < numElements; i++) {
    result[i] = start + i;
  }
  return result;
}

function pagesToSequences(
  currentPage: number,
  numPages: number,
  elementsPerSide: number
) {
  const elementsPerLeftSide =
    elementsPerSide +
    Math.max(0, elementsPerSide - (numPages - 1 - currentPage));
  const elementsPerRightSide =
    elementsPerSide + Math.max(0, elementsPerSide - currentPage);

  let leftNum = Math.max(currentPage - elementsPerLeftSide, 0);
  if (leftNum === 1) {
    leftNum = 0;
  }
  let rightNum = Math.min(currentPage + elementsPerRightSide, numPages - 1);
  if (rightNum === numPages - 2) {
    rightNum = numPages - 1;
  }
  const leftHole = leftNum !== 0;
  const rightHole = rightNum !== numPages - 1;
  const result = [sequence(leftNum, rightNum - leftNum + 1)];
  if (leftHole) {
    result.unshift([0]);
  }
  if (rightHole) {
    result.push([numPages - 1]);
  }
  return result;
}

function setParam(
  oldParams: Array<[string, string]>,
  key: string,
  value: string
) {
  const params = new URLSearchParams(oldParams);
  params.set(key, value);
  return params.toString();
}

export default function QueryPagination(props: {
  numPages: number;
  queryParameter: string;
}) {
  const [params] = useSearchParams();
  const paramList = useMemo(() => Array.from(params.entries()), [params]);
  let currentPageParam = parseInt(params.get(props.queryParameter) ?? '');
  if (isNaN(currentPageParam)) {
    currentPageParam = 0;
  }
  const lastPage = props.numPages === 0 ? 1 : props.numPages - 1;
  const currentPage = Math.min(lastPage, Math.max(0, currentPageParam));
  const searchParamsForPage = (page: number) =>
    setParam(paramList, props.queryParameter, page.toString());
  const prevDisabled = currentPage === 0;
  const nextDisabled = currentPage === lastPage;
  const pagePicks = pagesToSequences(currentPage, props.numPages, 2);
  const pageComponents = pagePicks.map((col, i) => {
    const items = col.map((pageNum) => (
      <Link
        key={pageNum}
        to={{ search: searchParamsForPage(pageNum) }}
        className={navLinkStyle(currentPage === pageNum)}
      >
        {pageNum + 1}
      </Link>
    ));
    if (i !== pagePicks.length - 1) {
      items.push(<div className={navLinkStyle(false)}>...</div>);
    }
    return items;
  });

  const prevSearch = searchParamsForPage(currentPage - 1);
  const nextSearch = searchParamsForPage(currentPage + 1);

  return (
    <nav className="border-gray-200 border-t pt-5 flex flex-row justify-between items-center">
      <ReactRouterLinkButton
        displayType="link"
        size="sm"
        hasColor={false}
        to={{ search: prevSearch }}
        leadingIcon={LeftIcon}
        disabled={prevDisabled}
        label="Previous"
      />
      <div className="flex gap-0.5">{pageComponents}</div>
      <ReactRouterLinkButton
        displayType="link"
        size="sm"
        hasColor={false}
        to={{ search: nextSearch }}
        trailingIcon={RightIcon}
        disabled={nextDisabled}
        label="Next"
      />
    </nav>
  );
}
