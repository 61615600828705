import type { AlertData, AlertType } from 'client/alertManager';
import AlertTriangle from 'assets/svg/tabler/alert-triangle.svg';
import AlertCircle from 'assets/svg/tabler/alert-circle.svg';
import CircleCheck from 'assets/svg/tabler/circle-check.svg';
import XIcon from 'assets/svg/tabler/x.svg';

function detailColors(type: AlertType) {
  switch (type) {
    case 'warning':
      return 'text-warning-600';
    case 'error':
      return 'text-error-600';
    case 'notice':
      return 'text-gray-600';
    case 'success':
      return 'text-success-600';
  }
}

function messageColors(type: AlertType) {
  switch (type) {
    case 'warning':
      return 'text-warning-700';
    case 'error':
      return 'text-error-700';
    case 'notice':
      return 'text-gray-700';
    case 'success':
      return 'text-success-700';
  }
}

function mainColors(type: AlertType) {
  switch (type) {
    case 'warning':
      return 'bg-warning-25 border-warning-300';
    case 'error':
      return 'bg-error-25 border-error-300';
    case 'notice':
      return 'bg-gray-25 border-gray-300';
    case 'success':
      return 'bg-success-25 border-success-300';
  }
}

function Icon(type: AlertType) {
  const className = `stroke-[1.67] w-9 h-9 p-2 shrink-0 block ${detailColors(
    type
  )}`;
  switch (type) {
    case 'warning':
      return <AlertTriangle className={className} />;
    case 'error':
      return <AlertCircle className={className} />;
    case 'notice':
      return <AlertCircle className={className} />;
    case 'success':
      return <CircleCheck className={className} />;
  }
}

export function Alert(
  props: AlertData & { className?: string; onClose: () => void }
) {
  const className = props.className ?? '';
  return (
    <div
      className={`${className} flex flex-col border rounded-lg p-4 gap-3 shadow-lg ${mainColors(
        props.type
      )}`}
    >
      <div className="flex flex-row justify-between items-start">
        {Icon(props.type)}
        <div className="grow flex flex-col gap-2 pt-2">
          <h2 className={`text-sm-medium ${messageColors(props.type)}`}>
            {props.message}
          </h2>
          <span className={`text-sm-light ${detailColors(props.type)}`}>
            {props.details}
          </span>
        </div>
        <button
          type="button"
          aria-label="Close"
          className="cursor-pointer shrink-0 p-2 inline-flex border-0 bg-transparent"
        >
          <XIcon
            className={`w-5 h-5 stroke-[1.67] ${messageColors(props.type)}`}
            onClick={props.onClose}
          />
        </button>
      </div>
    </div>
  );
}
