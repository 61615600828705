let globalTagCache: Readonly<Record<number, string>> = {};
type Subscription = (value: typeof globalTagCache) => void;
const cacheSubscribers = new Set<Subscription>();

export function updateCache(tags: Array<{ id: number; name: string }>) {
  const newCache = { ...globalTagCache };
  let didUpdate = false;
  for (const tag of tags) {
    const oldVal = newCache[tag.id];
    if (tag.name !== oldVal) {
      didUpdate = true;
    }
    newCache[tag.id] = tag.name;
  }
  if (didUpdate) {
    globalTagCache = newCache;
    for (const subscriber of cacheSubscribers) {
      subscriber(globalTagCache);
    }
  }
}

export function subscribe(subscription: Subscription) {
  cacheSubscribers.add(subscription);
}

export function unsubscribe(subscription: Subscription) {
  cacheSubscribers.delete(subscription);
}

export { globalTagCache };
