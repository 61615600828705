import { trpc } from 'shared/trpc';
import LoadingOverlay from './LoadingOverlay';
import CheckBox from './CheckBox';
import { useMemo, useState } from 'react';
import TagPicker from './TagPicker';
import { type z } from 'zod';
import { type ResourceType } from 'shared/validators';

export default function TagFilter(props: {
  selected: number[];
  resource: z.infer<typeof ResourceType>;
  updateSelection: (selection: number[]) => void;
}) {
  const request = trpc.queryTagsForResource.useSWR(props.resource);
  const selectedSet = useMemo(() => new Set(props.selected), [props.selected]);
  const tagNames = new Map(request.data?.map((el) => [el.id, el]));
  const [searchKey, setSearchKey] = useState('');
  const picks = props.selected.map((id) => {
    return { id, name: tagNames.get(id)?.name ?? 'Loading' };
  });
  const pickOptions =
    searchKey === ''
      ? []
      : request.data?.filter((el) => el.name.startsWith(searchKey)) ??
        'loading';
  const addToSelection = (addedId: number) => {
    props.updateSelection([...props.selected, addedId]);
  };
  const removeFromSelection = (deletedId: number) => {
    props.updateSelection(props.selected.filter((id) => id !== deletedId));
  };

  return (
    <div className="p-4 bg-base-white rounded-lg border border-gray-100 flex flex-col items-stretch gap-6 relative w-80">
      <div className="flex flex-col">
        <div className="py-2 text-sm-semibold text-gray-700">Tag Search</div>
        <TagPicker
          label={''}
          clearSearch={true}
          searchUpdated={setSearchKey}
          tagAdded={addToSelection}
          tagDeleted={removeFromSelection}
          pickOptions={pickOptions}
          textArea={false}
          selectedTags={picks}
        />
      </div>
      <div className="flex flex-col">
        <div className="py-2 text-sm-semibold text-gray-700">Browse Tags</div>
        <div className="overflow-auto max-h-[10rem]">
          {request.data?.map((el) => (
            <div className="flex gap-3 py-2" key={el.id}>
              <CheckBox
                checked={selectedSet.has(el.id)}
                onToggle={() => {
                  selectedSet.has(el.id)
                    ? removeFromSelection(el.id)
                    : addToSelection(el.id);
                }}
              />
              <div className="grow text-sm-light text-gray-700">{el.name}</div>
              <div className="px-2 py-0.5 bg-gray-100 text-gray-700 text-xs-medium rounded-full tabular-nums ">
                {el.count}
              </div>
            </div>
          )) ?? null}
        </div>
      </div>
      <LoadingOverlay loading={request.isLoading} />
    </div>
  );
}
