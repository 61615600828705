import { type z } from 'zod';
import TextInput, { type InputType, type TextAreaType } from './TextInput';
import { useState } from 'react';

export type ParseResult<T> =
  | {
      error: true;
      errors: string[];
    }
  | { error: false; value: T; errors: [] };

export interface Serializer<T> {
  toString: (val: T) => string;
  parse: (val: string) => ParseResult<T>;
}

export function zodSerializer<T extends z.ZodType<string>>(schema: T) {
  return {
    toString(val: z.infer<typeof schema>) {
      return val;
    },
    parse: (val: string): ParseResult<z.infer<typeof schema>> => {
      const result = schema.safeParse(val);
      if (result.success) {
        return { error: false, errors: [], value: result.data };
      } else {
        const error = result.error.flatten();
        return {
          error: true,
          errors: error.formErrors.length === 0 ? ['Error'] : error.formErrors,
        };
      }
    },
  };
}

export const JSONSerializer = {
  toString: (val: Record<string, any>) => {
    return JSON.stringify(val, null, 2);
  },
  parse: (val: string): Record<string, any> => {
    try {
      const result = JSON.parse(val);
      return { error: false, value: result, errors: [] };
    } catch (e) {
      if (e instanceof Error) {
        return { error: true, errors: [e.message] };
      } else {
        return { error: true, errors: ['Error'] };
      }
    }
  },
};

export const DateSerializer = {
  toString: (val: Date | null) => {
    if (val === null) {
      return '';
    }
    return val.toISOString().split('T')[0];
  },
  parse: (val: string): ParseResult<Date | null> => {
    if (val === '') {
      return { error: false, value: null, errors: [] };
    }
    if (!val.match(/^\d{4}-\d{2}-\d{2}/)) {
      return { error: true, errors: ['Invalid Date'] };
    }
    const parsed = new Date(val);
    if (parsed.toString() === 'Invalid Date') {
      return { error: true, errors: ['Invalid Date'] };
    }
    return { error: false, value: parsed, errors: [] };
  },
};

type Omitted = 'value' | 'onInput' | 'message' | 'messageType';
export default function ValidatingTextInput<T>({
  serializer,
  setValue,
  value,
  errors = [],
  ...rest
}: (Omit<TextAreaType, Omitted> | Omit<InputType, Omitted>) & {
  serializer: Serializer<T>;
  value: T;
  setValue: (value: T) => void;
  errors?: string[];
}) {
  const currentStringValue = serializer.toString(value);
  const [lastValue, setLastValue] = useState(currentStringValue);
  const [internalValue, setInternalValue] = useState(currentStringValue);
  const [internalErrors, setInternalErrors] = useState<string[]>(
    () => serializer.parse(internalValue).errors
  );
  const allErrors = [...internalErrors, ...errors];

  if (lastValue !== currentStringValue) {
    setLastValue(currentStringValue);
    setInternalValue(currentStringValue);
  }

  function changed(e: string) {
    setInternalValue(e);
    const validated = serializer.parse(e);
    if (validated.error) {
      setInternalErrors(validated.errors);
    } else {
      setInternalErrors([]);
      setValue(validated.value);
    }
  }

  return (
    <TextInput
      value={internalValue}
      onInput={changed}
      message={allErrors.join('\n')}
      messageType={allErrors.length === 0 ? 'default' : 'error'}
      {...rest}
    ></TextInput>
  );
}
