import { AlertManagerContext } from 'client/alertManager';
import Button from 'client/components/Button';
import TextInput from 'client/components/TextInput';
import { useContext, useState } from 'react';
import { Form } from 'react-router-dom';
import { fetchWrapper, ignorePromise } from 'shared/utils';
import useSWRMutation from 'swr/mutation';
import LocationPicker from 'client/components/LocationPicker';

export function Login(props: Record<string, unknown>) {
  const alertManager = useContext(AlertManagerContext);
  const login = useSWRMutation('/login', sendRequest);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  async function sendRequest(
    url: string,
    { arg }: { arg: { email: string; password: string } }
  ) {
    const res = await fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify(arg),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (res.ok) {
      window.location.reload();
    } else {
      alertManager.addAlert({
        message: 'Invalid email or password',
        details: '',
        type: 'error',
      });
    }
  }
  return (
    <div className="w-full h-full py-20 lg:pt-44 bg-[url('assets/images/login_backdrop.jpg')] bg-cover bg-left flex items-center flex-row">
      <div className="grow min-w-6"></div>
      <div className="inline-block bg-base-white rounded-3xl p-12 max-w-[36.25rem]">
        <div className="flex flex-col gap-5">
          <h2 className="text-xl-semibold lg:display-md-semibold text-base-black">
            Welcome to the Kreitzberg Archives!
          </h2>
          <span className="text-md-light lg:text-lg-light text-gray-500">
            Please enter login details to access the database.
          </span>
          <Form
            action="/login"
            method="post"
            className="flex flex-col gap-5"
            onSubmit={ignorePromise(async (e) => {
              e.preventDefault();
              await login.trigger({ email, password });
            })}
          >
            <TextInput
              type="text"
              value={email}
              name="Email"
              label="Email"
              placeholder="Email"
              onInput={(val) => {
                setEmail(val);
              }}
              passthrough={{ required: true }}
            />

            <TextInput
              type="password"
              value={password}
              name="Password"
              label="Password"
              placeholder="Password"
              onInput={(val) => {
                setPassword(val);
              }}
              passthrough={{ required: true }}
            />
            {
              // TODO: fix button type, submit for accessibility
            }
            <Button
              type="submit"
              className="w-min"
              label="Login"
              size="xl"
              disabled={login.isMutating}
            />
          </Form>
        </div>
        <div className="grow max-w-[5rem] min-w-[1.5rem]"></div>
      </div>
    </div>
  );
}
