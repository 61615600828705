import Card from './Card';
import CheckBox from './CheckBox';

export function SelectableCard(
  props: React.PropsWithChildren<{
    selected: boolean;
    selectChanged: (selected: boolean) => void;
    className?: string;
    disabled?: boolean;
  }>
) {
  return (
    <Card
      className={props.className}
      selected={props.selected}
      onClick={() => {
        props.selectChanged(!props.selected);
      }}
    >
      {props.children}
      <div className="absolute top-0 right-0 p-4">
        <CheckBox
          checked={props.selected}
          disabled={props.disabled}
          onToggle={() => {
            props.selectChanged(!props.selected);
          }}
        />
      </div>
    </Card>
  );
}
