import TextInput, { type InputType, type TextAreaType } from './TextInput';

type Omitted = 'onInput' | 'message' | 'messageType' | 'errors' | 'setValue';

export type ErrorTextInputType = (
  | Omit<TextAreaType, Omitted>
  | Omit<InputType, Omitted>
) & {
  errors?: string[];
  setValue: (val: string) => void;
};

export default function ErrorTextInput({
  errors = [],
  setValue,
  ...rest
}: ErrorTextInputType) {
  return (
    <TextInput
      onInput={setValue}
      message={errors.join('\n')}
      messageType={errors.length === 0 ? 'default' : 'error'}
      {...rest}
    ></TextInput>
  );
}
