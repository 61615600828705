import { useEffect, useState } from 'react';
import { LoadingIndicator } from './LoadingIndicator';

export default function LoadingOverlay(props: { loading: boolean }) {
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    setInitialRender(false);
  }, []);

  let classes;
  if (initialRender) {
    classes = 'opacity-0 invisible';
  } else if (props.loading) {
    classes = 'opacity-100 visible';
  } else {
    classes = 'opacity-0 invisible';
  }
  return (
    <div
      className={`${classes} transition-[visibility, opacity] duration-1000 top-0 left-0 right-0 bottom-0 absolute bg-base-white/90 flex flex-col justify-center items-center`}
    >
      <LoadingIndicator />
    </div>
  );
}
