import { useState, useEffect, useCallback } from 'react';
import { client, trpc } from 'shared/trpc';
import TagPicker from './TagPicker';
import { useDebounced, useKvCache } from 'shared/utils';

export default function AddLocationPicker(props: {
  label?: string;
  locationIds: number[];
  textArea?: boolean;
  setLocationIds: (tags: number[]) => void;
}) {
  const [tagSearchKey, setTagSearchKey] = useState('');
  const [tagSearchKeyDebounced, setTagSearchKeyDebounced] = useDebounced(
    tagSearchKey,
    500
  );

  const tagCache = useKvCache(
    props.locationIds,
    useCallback(async (keys) => {
      const res = await client.getLocations.query(keys);
      return res.map((el): [number, string] => [el.id, el.title]);
    }, [])
  );

  const tagList = props.locationIds.map((id) => {
    return { id, name: tagCache.get(id) };
  });

  const searchResult = trpc.filterLocations.useSWR(tagSearchKeyDebounced, {
    keepPreviousData: true,
    isDisabled: tagSearchKeyDebounced === '',
    onError: (err) => {
      console.log(JSON.stringify(err, null, 2));
    },
  });
  const normalized =
    searchResult.data?.map((el) => ({ id: el.id, name: el.title })) ?? [];
  const hasExactMatch =
    normalized.some((el) => el.name === tagSearchKey) ?? false;
  const dedupedTags =
    tagSearchKey === ''
      ? []
      : searchResult.isLoading || tagSearchKey !== tagSearchKeyDebounced
      ? 'loading'
      : normalized
          .filter((tag) => !props.locationIds.includes(tag.id))
          .concat(
            hasExactMatch ? [] : [{ id: -1, name: `Create ${tagSearchKey}` }]
          ) ?? 'loading';

  function tagDeleted(id: number) {
    props.setLocationIds(props.locationIds.filter((el) => el !== id));
  }

  async function tagAdded(id: number) {
    if (id === -1) {
      // create the new tag
      // Add new tag
      const result = await client.createLocation.mutate({
        title: tagSearchKey,
        tags: [],
        adminNotes: '',
      });
      void searchResult.mutate();
      if (result.error) {
        return;
      }
      id = result.data;
    }
    props.setLocationIds([...props.locationIds, id]);
  }

  function searchUpdated(e: string) {
    setTagSearchKey(e);
    if (e === '') {
      // do not debounce disabled search
      setTagSearchKeyDebounced(e);
    }
  }
  return (
    <TagPicker
      searchUpdated={searchUpdated}
      label={props.label}
      tagAdded={tagAdded}
      tagDeleted={tagDeleted}
      pickOptions={dedupedTags}
      textArea={props.textArea ?? false}
      selectedTags={tagList.map((tag) => {
        return { id: tag.id, name: tag.name || 'Loading...' };
      })}
    />
  );
}
