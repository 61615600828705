import { cva } from 'class-variance-authority';

export interface OwnProps {
  color: 'primary' | 'success' | 'error';
  className?: string;
  progress: number;
}

const barClases = cva('w-full h-full rounded', {
  variants: {
    color: {
      primary: 'bg-primary-600',
      success: 'bg-success-400',
      error: 'bg-error-500',
    },
  },
});

export function Progress(props: OwnProps) {
  const className = props.className ?? '';
  const cvaProps = {
    color: props.color,
  };
  return (
    <div
      className={`${className} w-full h-2 rounded bg-gray-200 overflow-hidden`}
    >
      <div
        className={barClases(cvaProps)}
        style={{ transform: `translateX(${-100 + props.progress}%)` }}
      ></div>
    </div>
  );
}
