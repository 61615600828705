import { cva } from 'class-variance-authority';
import { forwardRef, Ref } from 'react';
import {
  type LinkProps as ReactRouterLinkProps,
  Link as ReactRouterLink,
} from 'react-router-dom';

export interface SharedProps {
  className?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  displayType?: 'primary' | 'secondary' | 'tertiary' | 'link';
  stretch?: 'horizontal' | 'vertical' | 'both' | 'none';
  hasColor?: boolean;
  destructive?: boolean;
  disabled?: boolean;
  leadingIcon?: React.FunctionComponent<{ className?: string }>;
  trailingIcon?: React.FunctionComponent<{ className?: string }>;
  label?: string;
}

const nonLinkTypes = ['primary', 'secondary', 'tertiary'] as const;
const allTypes = [...nonLinkTypes, 'link'] as const;
const nonLinkCompound = [...nonLinkTypes];

function getCompounds(args: {
  displayType: (typeof allTypes)[number];
  color?: boolean;
  destructive: boolean;
  classes: { base: string; disabled: string; nonDisabled: string };
}) {
  const result = [
    { displayType: args.displayType, class: args.classes.base },
    {
      displayType: args.displayType,
      disabled: true,
      class: args.classes.disabled,
    },
    {
      displayType: args.displayType,
      disabled: false,
      class: args.classes.nonDisabled,
    },
  ];
  return result.map((el) =>
    args.color === undefined ? el : { ...el, color: args.color }
  );
}

function iconClasses(leading: boolean, size: Required<SharedProps>['size']) {
  if (size === 'xxl') {
    return `w-6 h-6 stroke-[2] ${
      leading ? 'mr-3 last:mr-0' : 'ml-3 first:ml-0'
    }`;
  } else {
    return `w-5 h-5 stroke-[1.67] ${
      leading ? 'mr-2 last:mr-0' : 'ml-2 first:ml-0'
    }`;
  }
}
const butonClasses = cva(
  'relative ring-0 inline-block whitespace-nowrap select-none align-middle appearance-none rounded-lg hover:no-underline',
  {
    variants: {
      displayType: {
        primary: '',
        secondary: '',
        tertiary: '',
        link: '',
      },
      iconOnly: {
        true: '',
        false: '',
      },
      color: {
        true: '',
        false: '',
      },
      destructive: {
        true: '',
        false: '',
      },
      disabled: {
        true: 'pointer-events-none cursor-default',
        false: 'cursor-pointer',
      },
      stretch: {
        none: '',
        horizontal: 'w-full block',
        vertical: 'h-full block',
        both: 'w-full h-full block',
      },
      size: {
        sm: 'text-sm-medium',
        md: 'text-sm-medium',
        lg: 'text-md-medium',
        xl: 'text-md-medium',
        xxl: 'text-lg-medium',
      },
    },
    compoundVariants: [
      {
        displayType: nonLinkCompound,
        size: 'sm',
        iconOnly: true,
        class: 'p-2',
      },
      {
        displayType: nonLinkCompound,
        size: 'sm',
        iconOnly: false,
        class: 'py-2 px-3.5',
      },
      {
        displayType: nonLinkCompound,
        size: 'md',
        iconOnly: true,
        class: 'p-2.5',
      },
      {
        displayType: nonLinkCompound,
        size: 'md',
        iconOnly: false,
        class: 'py-2.5 px-4',
      },
      {
        displayType: nonLinkCompound,
        size: 'lg',
        iconOnly: true,
        class: 'p-3',
      },
      {
        displayType: nonLinkCompound,
        size: 'lg',
        iconOnly: false,
        class: 'py-2.5 px-4.5',
      },
      {
        displayType: nonLinkCompound,
        size: 'xl',
        iconOnly: true,
        class: 'p-3.5',
      },
      {
        displayType: nonLinkCompound,
        size: 'xl',
        iconOnly: false,
        class: 'py-3 px-5',
      },
      {
        displayType: nonLinkCompound,
        size: 'xxl',
        iconOnly: true,
        class: 'p-4',
      },
      {
        displayType: nonLinkCompound,
        size: 'xxl',
        iconOnly: false,
        class: 'py-4 px-6',
      },
      // Non destructive
      ...getCompounds({
        displayType: 'primary',
        destructive: false,
        classes: {
          base: 'text-base-white hover:bg-primary-700 hover:border-primary-700 ring-primary-100 border active:ring-4',
          disabled: 'bg-primary-200 border-primary-200',
          nonDisabled: 'bg-primary-600 border-primary-600',
        },
      }),
      ...getCompounds({
        displayType: 'secondary',
        destructive: false,
        color: false,
        classes: {
          base: 'bg-base-white hover:bg-gray-50 hover:text-gray-800 active:ring-gray-100 border active:ring-4',
          disabled: 'text-gray-300 border-gray-200',
          nonDisabled: 'text-gray-700 border-gray-300',
        },
      }),
      ...getCompounds({
        displayType: 'secondary',
        destructive: false,
        color: true,
        classes: {
          base: 'hover:bg-primary-100 hover:border-primary-100 active:ring-primary-100 border active:ring-4',
          disabled: 'bg-primary-25 text-primary-300 border-primary-25',
          nonDisabled: 'bg-primary-50 text-primary-700 border-primary-50',
        },
      }),
      ...getCompounds({
        displayType: 'tertiary',
        destructive: false,
        color: false,
        classes: {
          base: 'bg-transparent  border-transparent hover:bg-gray-50 hover:text-gray-600 hover:border-gray-50 border active:ring-4',
          disabled: 'text-gray-300',
          nonDisabled: 'text-gray-500',
        },
      }),
      ...getCompounds({
        displayType: 'tertiary',
        destructive: false,
        color: true,
        classes: {
          base: 'bg-transparent border-transparent hover:bg-gray-50 hover:border-gray-50 border active:ring-4',
          disabled: 'text-gray-300',
          nonDisabled: 'text-primary-700',
        },
      }),
      ...getCompounds({
        displayType: 'link',
        destructive: false,
        color: false,
        classes: {
          base: 'bg-transparent border-transparent hover:text-gray-600',
          disabled: 'text-gray-300',
          nonDisabled: 'text-gray-500 ',
        },
      }),
      ...getCompounds({
        displayType: 'link',
        destructive: false,
        color: true,
        classes: {
          base: 'bg-transparent border-transparent hover:text-primary-800',
          disabled: 'text-gray-300',
          nonDisabled: 'text-primary-700 ',
        },
      }),

      // Destructive
      ...getCompounds({
        displayType: 'primary',
        destructive: true,
        classes: {
          base: 'text-base-white hover:bg-error-700 hover:border-error-700 ring-error-100 border active:outline',
          disabled: 'bg-error-200 border-error-200',
          nonDisabled: 'bg-error-600  border-error-600',
        },
      }),
      ...getCompounds({
        displayType: 'secondary',
        destructive: true,
        color: false,
        classes: {
          base: 'bg-base-white hover:bg-error-50 hover:text-error-800 active:ring-error-100 border active:ring',
          disabled: 'text-error-300 border-error-200',
          nonDisabled: 'text-error-700 border-error-300',
        },
      }),
      ...getCompounds({
        displayType: 'secondary',
        destructive: true,
        color: true,
        classes: {
          base: 'hover:bg-error-100 hover:border-error-100 active:ring-error-100 border active:ring',
          disabled: 'bg-error-25 text-error-300 border-error-25',
          nonDisabled: 'bg-error-50 text-error-700 border-error-50',
        },
      }),
      ...getCompounds({
        displayType: 'tertiary',
        destructive: true,
        color: false,
        classes: {
          base: 'bg-transparent border-transparent hover:bg-error-50 hover:border-error-50 border active:ring',
          disabled: 'text-error-300',
          nonDisabled: 'text-error-500',
        },
      }),
      ...getCompounds({
        displayType: 'tertiary',
        destructive: true,
        color: true,
        classes: {
          base: 'bg-transparent border-transparent hover:bg-error-50 hover:border-error-500 border active:ring',
          disabled: 'text-error-300',
          nonDisabled: 'text-error-700',
        },
      }),
      ...getCompounds({
        displayType: 'link',
        destructive: true,
        color: false,
        classes: {
          base: 'bg-transparent border-transparent hover:text-error-600',
          disabled: 'text-error-300',
          nonDisabled: 'text-error-500 ',
        },
      }),
      ...getCompounds({
        displayType: 'link',
        destructive: true,
        color: true,
        classes: {
          base: 'bg-transparent border-transparent hover:text-error-800',
          disabled: 'text-error-300',
          nonDisabled: 'text-error-700 ',
        },
      }),
    ],
  }
);

const defaultButtonWrapper = forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<'button'>
>((props, ref) => {
  const { children, ...rest } = props;
  return (
    <button ref={ref} {...rest}>
      {children}
    </button>
  );
});
defaultButtonWrapper.displayName = 'defaultButtonWrapper';

const defaultLinkWrapper = forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithRef<'a'>
>((props, ref) => {
  const { children, ...rest } = props;
  return (
    <a ref={ref} {...rest}>
      {children}
    </a>
  );
});
defaultLinkWrapper.displayName = 'defaultLinkWrapper';

const reactRouterLinkWrapper = forwardRef<
  HTMLAnchorElement,
  ReactRouterLinkProps
>((props, ref) => {
  const { children, ...rest } = props;
  return (
    <ReactRouterLink ref={ref} {...rest}>
      {children}
    </ReactRouterLink>
  );
});
reactRouterLinkWrapper.displayName = 'reactRouterLinkWrapper';

type ButtonProps = Omit<React.ComponentPropsWithoutRef<'button'>, 'children'> &
  SharedProps & { children?: typeof defaultButtonWrapper };

type LinkProps = Omit<React.ComponentPropsWithoutRef<'a'>, 'children'> &
  SharedProps & { children?: typeof defaultLinkWrapper };

type ReactRouterLinkWrapperProps = Omit<ReactRouterLinkProps, 'children'> &
  SharedProps & { children?: typeof reactRouterLinkWrapper };

export function CreateButtonComponent<
  T extends
    | typeof defaultButtonWrapper
    | typeof defaultLinkWrapper
    | typeof reactRouterLinkWrapper
>(wrapper: T) {
  const BaseButton = (
    allProps: T extends typeof defaultButtonWrapper
      ? ButtonProps
      : T extends typeof defaultLinkWrapper
      ? LinkProps
      : ReactRouterLinkWrapperProps,
    ref: T extends typeof defaultButtonWrapper
      ? React.ForwardedRef<HTMLButtonElement>
      : React.ForwardedRef<HTMLAnchorElement>
  ) => {
    // I've tried everything, no way to make this type
    let Children: any = wrapper;
    let {
      destructive,
      hasColor,
      displayType,
      stretch,
      className,
      size,
      label,
      trailingIcon: TrailingIcon,
      leadingIcon: LeadingIcon,
      children,
      ...rest
    } = allProps;
    className ??= '';
    size ??= 'xl';
    displayType ??= 'primary';
    destructive ??= false;
    stretch ??= 'none';
    hasColor ??= true;
    label ??= '';
    const type = rest.type ?? 'button';
    const newRest = { ...rest, type };
    if (children) {
      Children = children;
    }

    const cvaProps = {
      size,
      displayType,
      destructive,
      stretch,
      disabled: rest.disabled ?? false,
      color: hasColor,
      iconOnly: label === '',
    };

    return (
      <Children
        className={`${butonClasses(cvaProps)} ${className}`}
        ref={ref}
        {...newRest}
      >
        {
          <div className="flex flex-row items-center justify-center">
            {LeadingIcon ? (
              <LeadingIcon className={iconClasses(true, size)} />
            ) : null}
            {label ? <span>{label}</span> : null}
            {TrailingIcon ? (
              <TrailingIcon className={iconClasses(false, size)} />
            ) : null}
          </div>
        }
      </Children>
    );
  };
  return BaseButton;
}
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  CreateButtonComponent(defaultButtonWrapper)
);
Button.displayName = 'Button';

export const LinkButton = forwardRef<HTMLAnchorElement, LinkProps>(
  CreateButtonComponent(defaultLinkWrapper)
);
LinkButton.displayName = 'LinkButton';

export const ReactRouterLinkButton = forwardRef<
  HTMLAnchorElement,
  ReactRouterLinkWrapperProps
>(CreateButtonComponent(reactRouterLinkWrapper));
ReactRouterLinkButton.displayName = 'ReactRouterLinkButton';

export default Button;
