import { z } from 'zod';

export const UpdateArchiveImageSchema = z.object({
  title: z.string().nonempty().optional(),
  description: z.string().optional(),
  imageCreatedAt: z.date().nullable().optional(),
  adminNotes: z.string().optional(),
  tags: z.array(z.number()).optional(),
  locations: z.array(z.number()).optional(),
});

export const UpdateArchiveImageRequest = z.object({
  ids: z.array(z.number()),
  changes: UpdateArchiveImageSchema,
});

export const UpdateLocationSchema = z.object({
  title: z.string().nonempty().optional(),
  coordinates: z
    .object({
      lat: z.number(),
      lng: z.number(),
      googlePlace: z
        .object({
          placeId: z.string().nonempty(),
          formattedAddress: z.string(),
        })
        .nullable(),
    })
    .nullable()
    .optional(),
  adminNotes: z.string().optional(),
  tags: z.array(z.number()).optional(),
});

export const UpdateLocationsRequest = z.object({
  ids: z.array(z.number()),
  changes: UpdateLocationSchema,
});

export const CreateLocationRequest = z.object({
  title: z.string().nonempty(),
  coordinates: z
    .object({
      lat: z.number(),
      lng: z.number(),
      googlePlace: z
        .object({
          placeId: z.string().nonempty(),
          formattedAddress: z.string(),
        })
        .optional(),
    })
    .optional(),
  tags: z.array(z.number()),
  adminNotes: z.string(),
});

export const FilterTagRequest = z.string();
export const FilterLocationRequest = z.string();

export const CreateTagRequest = z.string();
export const CreateImageRequest = z.object({
  batchId: z.number(),
  id: z.number(),
  // TODO: fix typo
  filname: z.string(),
});

export const QueryTagsRequest = z.array(z.number());
export const GetLocationsRequest = z.array(z.number());
export const GetArchiveImagesRequest = z.array(z.number());
export const OrderType = z.enum(['created']);
export const OrderDirection = z.enum(['asc', 'desc']);
export const Order = z.object({ type: OrderType, direction: OrderDirection });
// TODO: delete/rename
export const ManageTestGetRequest = z.object({
  tags: z.array(z.number()),
  query: z.string(),
  order: Order.optional(),
  batch: z.number().optional(),
});

export const ResourceType = z.enum(['location', 'archiveImage']);

export const QueryTagsForResourceRequest = ResourceType;

export const RegisterUploadRequest = z.number().int();

export const UpdateImageNotesRequest = z.array(
  z.object({ id: z.number(), notes: z.string() })
);

export const UploadBatchRequest = z.object({
  id: z.number(),
});

export const SubmitUploadBatchRequest = z.object({
  title: z.string(),
  description: z.string(),
  images: UpdateImageNotesRequest,
});
