import type { PropsWithChildren } from 'react';
import { createContext, useContext, useRef, useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { createPortal } from 'react-dom';
import TagPicker from './TagPicker';
import { useDebounced, useUniqueKey } from 'shared/utils';
import useSWR from 'swr';
const loader = new Loader({
  // TODO: environment
  apiKey: 'AIzaSyCpqRO4KUp18PADuB4JBDqWOuusYkR9990',
  version: 'weekly',
});

interface Coordinates {
  lat: number;
  lng: number;
}

interface MapApiType {
  Map: typeof google.maps.Map;
  Marker: typeof google.maps.Marker;
  AutocompleteService: typeof google.maps.places.AutocompleteService;
  PlacesService: typeof google.maps.places.PlacesService;
  AutocompleteSessionToken: typeof google.maps.places.AutocompleteSessionToken;
}

interface MapContextType {
  map: google.maps.Map;
}

export const MapApi = createContext<MapApiType | undefined>(undefined);
export const useMapApi = () => {
  const mapApi = useContext(MapApi);
  if (!mapApi) {
    throw new Error('MapApi.Provider not found');
  }
  return mapApi;
};

export const MapContext = createContext<MapContextType | undefined>(undefined);
export const useMapContext = () => {
  const mapContext = useContext(MapContext);
  if (!mapContext) {
    throw new Error('MapContext.Provider not found');
  }
  return mapContext;
};

function GMapMarker(props: {
  initialLocation: Coordinates;
  locationUpdated: (loc: Coordinates | null) => void;
}) {
  const apiContext = useMapApi();
  const mapContext = useMapContext();
  const boundUpdate = useRef<(loc: Coordinates | null) => void>();
  const markerRef = useRef<{
    marker: google.maps.Marker;
    handle: google.maps.MapsEventListener;
  }>();

  useEffect(() => {
    boundUpdate.current = props.locationUpdated;
  }, [props.locationUpdated]);

  useEffect(() => {
    const marker = new apiContext.Marker({
      map: mapContext.map,
      position: props.initialLocation,
      draggable: true,
    });
    const handle = marker.addListener('dragend', () => {
      if (!boundUpdate.current) {
        return;
      }
      const loc = marker.getPosition();
      if (loc) {
        boundUpdate.current({ lat: loc.lat(), lng: loc.lng() });
      } else {
        boundUpdate.current(null);
      }
    });
    markerRef.current = { marker, handle };

    return () => {
      markerRef.current?.handle.remove();
      markerRef.current?.marker.setMap(null);
      markerRef.current = undefined;
    };
  }, []);
  return null;
}

function MapPosition(props: { location: Coordinates; zoom: number }) {
  const mapContext = useMapContext();
  useEffect(() => {
    mapContext.map.moveCamera({ center: props.location, zoom: props.zoom });
  }, []);
  return null;
}

function MapControl(props: PropsWithChildren<unknown>) {
  const mapContext = useMapContext();
  const [controlDiv, setControlDiv] = useState<HTMLDivElement>();
  useEffect(() => {
    const div = document.createElement('div');
    div.style.background = 'red';
    mapContext.map.controls[google.maps.ControlPosition.TOP_CENTER].push(div);
    setControlDiv(div);
    return () => {
      const controls =
        mapContext.map.controls[google.maps.ControlPosition.TOP_CENTER];
      for (let i = 0; i < controls.getLength(); i++) {
        if (controls.getAt(i) === div) {
          controls.removeAt(i);
          break;
        }
      }
    };
  }, []);
  return controlDiv ? createPortal(props.children, controlDiv) : null;
}

export function MapApiLoader(props: React.PropsWithChildren<unknown>) {
  const [gMapsState, setGmapState] = useState<MapApiType>();
  // TODO: error handling
  useEffect(() => {
    void Promise.all([
      loader.importLibrary('maps'),
      loader.importLibrary('marker'),
      loader.importLibrary('places'),
    ]).then((lib) => {
      const { Map } = lib[0];
      const { Marker } = lib[1];
      const { AutocompleteService, AutocompleteSessionToken, PlacesService } =
        lib[2];
      setGmapState({
        Map,
        Marker,
        AutocompleteService,
        AutocompleteSessionToken,
        PlacesService,
      });
    });
    return () => {};
  }, []);

  return gMapsState ? (
    <MapApi.Provider value={{ ...gMapsState }}>
      {props.children}
    </MapApi.Provider>
  ) : null;
}

export function Map(
  props: React.PropsWithChildren<{
    className: string;
  }>
) {
  const api = useMapApi();
  const mapDomRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  useEffect(() => {
    if (!mapDomRef.current) {
      throw new Error('ref assumption broken');
    }
    setMap(
      new api.Map(mapDomRef.current, { center: { lat: 0, lng: 0 }, zoom: 8 })
    );
    return () => {};
  }, []);

  return (
    <>
      <div className={props.className} ref={mapDomRef} />
      {map ? (
        <MapContext.Provider value={{ map }}>
          {props.children}
        </MapContext.Provider>
      ) : null}
    </>
  );
}

function MapPlaceSearch(props: {
  picked: string | null;
  locationDeleted: () => void;
  locationPicked: (arg: {
    placeId: string;
    coords: { lat: number; lng: number };
    formattedAddress: string;
  }) => void;
}) {
  const api = useMapApi();
  const attributionDiv = useRef<HTMLDivElement>(null);
  const picked =
    props.picked === null ? [] : [{ id: 'invalid', name: props.picked }];
  const [sessionToken, setSessionToken] = useState(
    () => new api.AutocompleteSessionToken()
  );

  const [searchKey, setSearchKey] = useState('');
  const [searchKeyDebounced, setsearchKeyDebounced] = useDebounced(
    searchKey,
    500
  );

  const fetcher = async (input: string) => {
    const service = new api.AutocompleteService();
    return await service.getPlacePredictions({ input, sessionToken });
  };

  const searchResult = useSWR(searchKeyDebounced, fetcher, {
    keepPreviousData: true,
    isDisabled: searchKeyDebounced === '',
    onError: (err) => {
      console.log(JSON.stringify(err, null, 2));
    },
  });

  const pickLocations =
    searchKey === ''
      ? []
      : searchResult.isLoading || searchKey !== searchKeyDebounced
      ? 'loading'
      : searchResult.data?.predictions.map((pred) => {
          return { id: pred.place_id, name: pred.description };
        }) ?? 'loading';

  function locationSelected(id: string) {
    if (!attributionDiv.current) {
      throw new Error('ref assumption broken');
    }
    const service = new api.PlacesService(attributionDiv.current);
    service.getDetails(
      {
        placeId: id,
        fields: ['geometry.location', 'formatted_address'],
        sessionToken,
      },
      (result, _status) => {
        // TODO: error
        const location = result?.geometry?.location;
        if (location) {
          props.locationPicked({
            placeId: id,
            coords: { lat: location.lat(), lng: location.lng() },
            formattedAddress: result.formatted_address ?? 'Unknown address',
          });
          setSessionToken(new api.AutocompleteSessionToken());
        }
      }
    );
  }
  function searchUpdated(e: string) {
    setSearchKey(e);
    if (e === '') {
      // do not debounce disabled search
      setsearchKeyDebounced(e);
    }
  }
  return (
    <>
      <TagPicker
        label={'Address Selection'}
        clearSearch={true}
        searchUpdated={searchUpdated}
        tagAdded={locationSelected}
        tagDeleted={props.locationDeleted}
        pickOptions={pickLocations}
        textArea={false}
        selectedTags={picked}
      />
      <div key={'attributions'} className="empty:hidden" ref={attributionDiv} />
    </>
  );
}

export interface Location {
  lat: number;
  lng: number;
  googlePlace: {
    placeId: string;
    formattedAddress: string;
  } | null;
}

function LocationPickerImpl(props: {
  initialLocation?: Location;
  locationPicked: (location: Location | null) => void;
}) {
  const [markerKey, invalidateMarker] = useUniqueKey('marker');
  const [cameraKey, invalidateCamera] = useUniqueKey('camera');
  const [markerLocation, setMarkerLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(props.initialLocation ?? null);
  const [markerMetadata, setMarkerMetadata] = useState<{
    formattedAddress: string;
    placeId: string;
  } | null>(props.initialLocation?.googlePlace ?? null);
  return (
    <div className="flex flex-col gap-9">
      <MapPlaceSearch
        picked={
          markerLocation ? markerMetadata?.formattedAddress ?? 'Custom' : null
        }
        locationDeleted={() => {
          setMarkerLocation(null);
          setMarkerMetadata(null);
          invalidateMarker();
          props.locationPicked(null);
        }}
        locationPicked={(loc) => {
          console.log(loc);
          setMarkerLocation(loc.coords);
          setMarkerMetadata(loc);
          invalidateMarker();
          invalidateCamera();
          props.locationPicked({
            ...loc.coords,
            googlePlace: {
              placeId: loc.placeId,
              formattedAddress: loc.formattedAddress,
            },
          });
        }}
      />
      <Map className="w-full aspect-[5/4] border rounded-lg border-gray-300">
        {markerLocation ? (
          <GMapMarker
            key={markerKey}
            initialLocation={markerLocation}
            locationUpdated={(loc) => {
              setMarkerMetadata(null);
              setMarkerLocation(loc);
              if (loc) {
                props.locationPicked({ ...loc, googlePlace: null });
              } else {
                props.locationPicked(null);
              }
            }}
          />
        ) : null}
        <MapPosition
          key={cameraKey}
          location={markerLocation ?? { lat: 37.6, lng: -95.665 }}
          zoom={markerLocation ? 17 : 4}
        />
      </Map>
    </div>
  );
}
export default function LocationPicker(props: {
  initialLocation?: Location;
  locationPicked: (location: Location | null) => void;
}) {
  return (
    <MapApiLoader>
      <LocationPickerImpl
        initialLocation={props.initialLocation}
        locationPicked={props.locationPicked}
      />
    </MapApiLoader>
  );
}
