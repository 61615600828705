import { NavLink } from 'react-router-dom';
import ArrowRight from 'assets/svg/tabler/arrow-right.svg';
import type { NavigationCategory, NavigationItem } from './MainNavigation';
import { Menu } from '@headlessui/react';
import React from 'react';
export const HeaderItem = React.forwardRef<
  HTMLAnchorElement,
  { item: NavigationItem; active: boolean }
>((props: { item: NavigationItem; active: boolean }, ref) => {
  const { active, item, ...rest } = props;
  return (
    <NavLink
      className={`${
        props.active ? 'bg-gray-50' : ''
      } flex flex-row flex-nowrap p-5 items-stretch justify-start`}
      to={props.item.to}
      ref={ref}
      {...rest}
    >
      <div className="w-6 flex justify-center items-start mr-4 flex-shrink-0">
        <props.item.icon className="text-primary-600 stroke-[2]" />
      </div>
      <div className="flex flex-col flex-nowrap gap-1">
        <span className="text-md-medium text-gray-900">{props.item.title}</span>
        <span className="text-sm-light text-gray-500">
          {props.item.subtitle}
        </span>
      </div>
    </NavLink>
  );
});
HeaderItem.displayName = 'HeaderItem';

export default function HeaderCategory(props: {
  category: NavigationCategory;
}) {
  return (
    <Menu.Items className="bg-base-white border border-gray-200 overflow-hidden rounded-lg top-[calc(100%_+_1rem)] shadow-lg">
      <div className="flex flex-nowrap flex-col">
        {props.category.items.map((item) => (
          <Menu.Item key={item.title}>
            {({ active }) => <HeaderItem item={item} active={active} />}
          </Menu.Item>
        ))}
      </div>
      <Menu.Item>
        {({ active }) => (
          <NavLink
            className={`${
              active ? 'bg-gray-50' : ''
            } p-8 w-full h-full flex bg-base-white border-t border-gray-200 items-center justify-center text-primary-700 text-md-medium`}
            to="google.com"
          >
            <span>{props.category.label}</span>
            <ArrowRight className="ml-2 w-5 h-5 stroke-[1.67]" />
          </NavLink>
        )}
      </Menu.Item>
    </Menu.Items>
  );
}
