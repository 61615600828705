import { cva } from 'class-variance-authority';

export interface OwnProps {
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  color:
    | 'primary'
    | 'gray'
    | 'error'
    | 'warning'
    | 'success'
    | 'primary-light'
    | 'gray-light'
    | 'error-light'
    | 'warning-light'
    | 'success-light'
    | 'primary-dark';
  iconType: 'circle' | 'primary';
  outline: boolean;
  icon: React.FunctionComponent<{ className?: string }>;
  className?: string;
}

const iconClasses = cva('', {
  variants: {
    size: {
      xs: 'w-3 h-3 stroke-1',
      sm: 'w-4 h-4 stroke-1',
      md: 'w-5 h-5 stroke-1',
      lg: 'w-6 h-6 stroke-2',
      xl: 'w-7 h-7 stroke-2',
    },
  },
});

const outerClasses = cva('flex items-center justify-center grow-0 shrink-0', {
  variants: {
    size: {
      xs: 'w-[1.5rem] h-[1.5rem]',
      sm: 'w-[2rem] h-[2rem]',
      md: 'w-[2.5rem] h-[2.5rem]',
      lg: 'w-[3rem] h-[3rem]',
      xl: 'w-[3.5rem] h-[3.5rem]',
    },
    color: {
      primary: 'text-base-white bg-primary-600 border-primary-700',
      gray: 'text-base-white bg-gray-600 border-gray-700',
      error: 'text-base-white bg-error-500 border-error-600',
      warning: 'text-base-white bg-warning-500 border-warning-600',
      success: 'text-base-white bg-success-500 border-success-600',
      'primary-light': 'text-primary-600 bg-primary-100 border-primary-50',
      'gray-light': 'text-gray-600 bg-gray-100 border-gray-50',
      'error-light': 'text-error-600 bg-error-100 border-error-50',
      'warning-light': 'text-warning-600 bg-warning-100 border-warning-50',
      'success-light': 'text-success-600 bg-success-100 border-success-50',
      'primary-dark': 'color-base-white bg-primary-800 border-primary-700',
    },
    iconType: {
      primary: 'rounded-xl',
      circle: 'rounded-full',
    },
    outline: {
      true: 'border-[5px]',
      false: 'border-0',
    },
  },
});

export function FeaturedIcon(props: OwnProps) {
  const className = props.className ?? '';
  const cvaProps = {
    size: props.size,
    iconType: props.iconType,
    outline: props.outline,
    color: props.color,
  };
  return (
    <div className={`${className} ${outerClasses(cvaProps)}`}>
      <props.icon className={iconClasses(cvaProps)}></props.icon>
    </div>
  );
}
