import { useNavigate, useOutletContext } from 'react-router-dom';
import Modal from './Modal';
import { client, trpc } from 'shared/trpc';
import TextInput from './TextInput';

import Cloud from 'assets/svg/tabler/cloud.svg';
import CloudUpload from 'assets/svg/tabler/cloud-upload.svg';
import {
  useDropzone,
  type DropzoneInputProps,
  type DropzoneRootProps,
} from 'react-dropzone';
import { FeaturedIcon } from './FeaturesIcon';
import { useEffect, useState, useSyncExternalStore } from 'react';
import { AllowedImageTypesExtensions } from 'shared/types';
import Card from './Card';
import Button from './Button';
import { allHeaders, csrfHeader, useDebounced } from 'shared/utils';
import { PromiseLimiter } from 'shared/queue';
import { Progress } from './Progress';
import LoadingOverlay from './LoadingOverlay';
import { useRequest } from 'client/hooks';

export interface OutletCtx {
  created: () => void;
}

function uploadFile(
  file: File,
  batchId: number,
  evt: (evt: { progress: number; finished: boolean; error: boolean }) => void
) {
  const realUploadState = {
    progress: 0,
    finished: false,
    error: false,
  };
  client.registerUpload.mutate(1).then(
    async (id) => {
      const formData = new FormData();
      formData.append('id', id[0].toString());
      formData.append('file', file);
      const req = new XMLHttpRequest();
      req.withCredentials = true;

      req.upload.addEventListener('progress', (e) => {
        realUploadState.progress = (e.loaded / e.total) * 0.8;
        evt(realUploadState);
      });
      req.addEventListener('error', (e) => {
        console.log(e);
        realUploadState.error = true;
        evt(realUploadState);
      });
      req.addEventListener('load', (e) => {
        realUploadState.progress = 0.8;
        evt(realUploadState);
        client.createUploadBatchImage
          .mutate({ id: id[0], filname: file.name, batchId: batchId })
          .then(
            (e) => {
              realUploadState.finished = true;
              realUploadState.progress = 1;
              evt(realUploadState);
            },
            (e) => {
              realUploadState.error = true;
              evt(realUploadState);
            }
          );
      });
      try {
        req.open('POST', '/api/upload');
        const [header, value] = Object.entries(allHeaders())[0];
        if (value) {
          req.setRequestHeader(header, value);
        }
        req.send(formData);
      } catch (e) {
        console.log(e);
        realUploadState.error = true;
        evt(realUploadState);
      }
    },
    (e) => {
      console.log(e);
      realUploadState.error = true;
      evt(realUploadState);
    }
  );
  return realUploadState;
}

type MultiUploadingState =
  | { type: 'idle' }
  | {
      type: 'started';
    }
  | { type: 'finished' }
  | { type: 'error' };

interface MultiUploadProgress {
  totalBytes: number;
  currentBytes: number;
}

type MultiUploadState = { progress: MultiUploadProgress } & MultiUploadingState;

class MultiUploader {
  filesToUpload: FileToUpload[];
  relativeFileWeights: number[];
  batchId: number;
  state: MultiUploadState;
  subscriptions: Array<() => void>;
  limiter: PromiseLimiter<Promise<void>>;
  uploadStates: Array<{
    progress: number;
    finished: boolean;
    error: boolean;
    file: FileToUpload;
  }>;

  constructor(batchId: number, filesToUpload: FileToUpload[]) {
    this.filesToUpload = [...filesToUpload];
    this.batchId = batchId;
    this.limiter = new PromiseLimiter(10);
    this.state = {
      type: 'idle',
      progress: {
        totalBytes: filesToUpload.reduce((acc, el) => el.file.size + acc, 0),
        currentBytes: 0,
      },
    };
    this.relativeFileWeights = this.filesToUpload.map(
      (el) => el.file.size / this.state.progress.totalBytes
    );
    this.subscriptions = [];
    this.uploadStates = [];
  }

  subscribe = (fun: () => void) => {
    this.subscriptions.push(fun);
    return fun;
  };

  unsubscribe = (fun: () => void) => {
    const idx = this.subscriptions.findIndex((el) => el === fun);
    if (idx === -1) {
      return;
    }
    this.subscriptions.splice(idx, 1);
  };

  updateState = (newState: MultiUploadState) => {
    this.state = { ...newState };
    for (const sub of this.subscriptions) {
      sub();
    }
  };

  getSnapshot = () => {
    return this.state;
  };

  deriveNewState = () => {
    let currentBytes = 0;
    let hasError = false;
    let finishedCount = 0;
    for (const [idx, file] of this.uploadStates.entries()) {
      currentBytes +=
        file.progress *
        this.state.progress.totalBytes *
        this.relativeFileWeights[idx];
      if (file.error) {
        hasError = true;
      }
      if (file.finished) {
        finishedCount++;
      }
    }
    if (finishedCount === this.filesToUpload.length) {
      if (hasError) {
        this.updateState({
          type: 'error',
          progress: { ...this.state.progress, currentBytes },
        });
      } else {
        client.finishUploadBatch.mutate({ id: this.batchId }).then(
          () => {
            this.updateState({
              type: 'finished',
              progress: {
                ...this.state.progress,
                currentBytes: this.state.progress.totalBytes,
              },
            });
          },
          () => {
            this.updateState({
              type: 'error',
              progress: { ...this.state.progress, currentBytes },
            });
          }
        );
      }
    } else {
      this.updateState({
        type: 'started',
        progress: { ...this.state.progress, currentBytes },
      });
    }
  };

  start = () => {
    if (this.state.type !== 'idle') {
      return;
    }
    this.updateState({
      type: 'started',
      progress: this.state.progress,
    });
    for (const file of this.filesToUpload) {
      const idx = this.uploadStates.length;
      this.uploadStates.push({
        progress: 0,
        finished: false,
        error: false,
        file,
      });
      void this.limiter.start(async () => {
        await new Promise<void>((resolve) => {
          uploadFile(file.file, this.batchId, (evt) => {
            if (evt.error) {
              this.uploadStates[idx].error = true;
              this.uploadStates[idx].finished = true;
              resolve();
            } else if (evt.finished) {
              this.uploadStates[idx].progress = 1;
              this.uploadStates[idx].finished = true;
              resolve();
            } else {
              this.uploadStates[idx].progress = evt.progress;
            }
            this.deriveNewState();
          });
        });
      });
    }
  };

  retry = () => {
    if (this.state.type !== 'error') {
      return;
    }
    this.updateState({
      type: 'started',
      progress: this.state.progress,
    });
    for (const file of this.uploadStates) {
      if (!file.error) {
        continue;
      }
      file.progress = 0;
      file.finished = false;
      file.error = false;
      void this.limiter.start(async () => {
        await new Promise<void>((resolve) => {
          uploadFile(file.file.file, this.batchId, (evt) => {
            if (evt.error) {
              file.error = true;
              file.finished = true;
              resolve();
            } else if (evt.finished) {
              file.progress = 1;
              file.finished = true;
              resolve();
            } else {
              file.progress = evt.progress;
            }
            this.deriveNewState();
          });
        });
      });
    }
  };
}

export function UploadCard(props: {
  dropZoneRootProps: () => DropzoneRootProps;
  dropZoneInputProps: () => DropzoneInputProps;
  isDrop: boolean;
  className?: string;
}) {
  const className = props.className ?? '';
  return (
    <div
      className={`cursor-pointer grow-[9999] flex flex-col items-center justify-center gap-3 px-4 py-6 border border-gray-200 rounded-lg ${className}`}
      {...props.dropZoneRootProps()}
    >
      <input {...props.dropZoneInputProps()} />
      <FeaturedIcon
        iconType="circle"
        size="md"
        color="gray-light"
        outline={true}
        icon={Cloud}
      />
      <div className="text-center select-none">
        {props.isDrop ? (
          <span className="text-sm-medium text-primary-700">
            Drop to upload
          </span>
        ) : (
          <>
            <span className="text-sm-medium text-primary-700">
              Click to upload
            </span>
            <span className="text-sm-light text-gray-500">
              {' '}
              or drag and drop
            </span>
          </>
        )}
      </div>
    </div>
  );
}

export function PreviewCard(props: { src: string }) {
  return (
    <Card
      className="flex flex-col shrink-0 aspect-[10/9] max-lg:w-[8rem] pointer-events-none"
      selected={false}
      standardSize={false}
    >
      <img
        className="h-0 w-full grow object-cover object-center pointer-events-none"
        src={props.src}
      ></img>
    </Card>
  );
}

interface FileToUpload {
  file: File;
  preview: string;
}

function Uploader(props: {
  files: FileToUpload[];
  setFiles: (files: FileToUpload[]) => void;
  title: string;
  setTitle: (title: string) => void;
  description: string;
  setDescription: (desc: string) => void;
  onUpload: () => void;
  onCancel: () => void;
}) {
  // React dropzones accept is broken... MIME is handled the same as other keys (i.e. image/* means all image types...) so just use extensions
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      '': Object.values(AllowedImageTypesExtensions).map((e) => `.${e}`),
    },
    onDrop: (acceptedFiles) => {
      const uploads = [];
      for (const file of acceptedFiles) {
        const preview = URL.createObjectURL(file);
        uploads.push({ file, preview });
      }
      props.setFiles([...props.files, ...uploads]);
    },
  });

  return (
    <div className="flex flex-col gap-8 w-full">
      <div className="flex flex-col gap-2">
        <div className="text-lg-medium text-gray-900">Upload Images</div>
        <div className="text-sm-light text-gray-500">
          Click or drag images from your computer to this uploader.
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-6 lg:gap-11 grow overflow-y-auto h-auto">
        <div className="flex flex-col gap-4 lg:basis-80 shrink-0 justify-stretch">
          <TextInput
            name="Batch Title"
            label="Batch Title"
            placeholder="Optional title for this batch of photos"
            type="text"
            value={props.title}
            onInput={props.setTitle}
          />
          <TextInput
            name="Batch Description"
            label="Batch Descrption"
            placeholder="Optional description for this batch photos. You will have the opportunity to describe individual photos in the next step."
            type="textarea"
            className="lg:grow"
            value={props.description}
            onInput={props.setDescription}
          />
        </div>
        {props.files.length === 0 ? (
          <UploadCard
            dropZoneRootProps={getRootProps}
            dropZoneInputProps={getInputProps}
            isDrop={isDragActive}
          ></UploadCard>
        ) : (
          <div className="grow flex flex-col gap-6">
            <UploadCard
              className="max-lg:hidden"
              dropZoneRootProps={getRootProps}
              dropZoneInputProps={getInputProps}
              isDrop={isDragActive}
            ></UploadCard>
            <div className="select-none flex flex-row overflow-auto lg:justify-center lg:flex-wrap gap-2 lg:gap-6 lg:grid-cols-[repeat(auto-fit,_15rem)] auto-rows-min lg:grid  ">
              {props.files.map((el, i) => (
                <PreviewCard key={i} src={el.preview} />
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-row justify-end gap-3">
        <Button
          hasColor={false}
          displayType="secondary"
          label="Cancel"
          onClick={props.onCancel}
        ></Button>
        <Button label="Upload" onClick={props.onUpload}></Button>
      </div>
    </div>
  );
}

function UploadProgress(props: { uploadProgress: MultiUploader }) {
  const uploaderState = useSyncExternalStore(
    props.uploadProgress.subscribe,
    props.uploadProgress.getSnapshot
  );
  const progress =
    (100 * uploaderState.progress.currentBytes) /
    uploaderState.progress.totalBytes;
  return (
    <div className="flex flex-col gap-8 w-full h-full">
      <div className="flex flex-col grow">
        <div className="grow flex justify-center items-center">
          <div className="flex flex-col gap-4 items-center max-w-[25rem]">
            <FeaturedIcon
              iconType="circle"
              size="lg"
              color="primary-light"
              outline={true}
              icon={CloudUpload}
            />
            <div className="flex flex-col gap-2 items-center text-center">
              <div className="text-lg-medium text-gray-900">Uploading</div>
              <div className="text-sm-light text-gray-500">
                Please do not close the page. You will be able to add
                descriptions in the next step.
              </div>
            </div>
            <div className="flex flex-row gap-3 max-w-[20rem] w-full items-center">
              <Progress
                className="grow"
                color={uploaderState.type === 'error' ? 'error' : 'primary'}
                progress={progress}
              ></Progress>
              <div className="tabular-nums">{Math.round(progress)}%</div>
            </div>
            {uploaderState.type === 'error' ? (
              <Button
                label="Retry"
                name="Retry"
                onClick={() => {
                  props.uploadProgress.retry();
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

function BatchSaver(props: {
  initialImages: Array<{ url: string; id: number; adminNotes: string }>;
  title: string;
  description: string;
  batchId: number;
  done: () => void;
}) {
  const [images, _] = useState(props.initialImages);
  const [currentNotes, setCurrentNotes] = useState(
    new Map(images.map((el) => [el.id, el.adminNotes]))
  );
  const [editedNotes, setEditedNotes] = useState<Map<number, string>>(
    new Map()
  );
  const [debouncedEdits, setDebouncedEdits] = useDebounced(editedNotes, 1000);
  const [updating, setUpdating] = useState(false);
  const [submitted, setSubmitted] = useState<false | true | 'canceled'>(false);
  const [networkError, setNetworkError] = useState(false);
  const doneCb = props.done;
  useEffect(() => {
    if (debouncedEdits.size === 0 || updating || submitted) {
      return;
    }
    setUpdating(true);

    async function apply() {
      try {
        await client.updateImageNotes.mutate(
          [...debouncedEdits.entries()].map(([id, notes]) => {
            return {
              id,
              notes,
            };
          })
        );
        // Persist successfull, there might be new changes in the meantime
        // We only want to persist changes we have not persisted yet
        const newEdits = new Map(editedNotes);
        // We also want to update our base to reflect the edits
        const newBase = new Map(currentNotes);
        for (const [id, persistedEdit] of debouncedEdits) {
          newBase.set(id, persistedEdit);
          const edit = newEdits.get(id);
          if (edit === persistedEdit) {
            newEdits.delete(id);
          }
        }
        setCurrentNotes(newBase);
        setEditedNotes(newEdits);
        setDebouncedEdits(new Map());
        setUpdating(false);
      } catch {
        // There was an error, retry persisting at next debouce point
        setDebouncedEdits(new Map());
        setUpdating(false);
      }
    }
    void apply();
  }, [
    debouncedEdits,
    updating,
    currentNotes,
    editedNotes,
    setDebouncedEdits,
    submitted,
  ]);

  useEffect(() => {
    if (updating || !submitted) {
      return;
    }
    setUpdating(true);

    async function apply() {
      try {
        if (submitted === 'canceled') {
          await client.cancelUploadBatch.mutate({ id: props.batchId });
        } else {
          await client.submitUploadBatch.mutate({
            title: props.title,
            description: props.description,
            images: [...editedNotes.entries()].map(([id, notes]) => {
              return {
                id,
                notes,
              };
            }),
          });
        }
        // Persist successfull flush UI
        const newBase = new Map(currentNotes);
        for (const [id, persistedEdit] of debouncedEdits) {
          newBase.set(id, persistedEdit);
        }
        setCurrentNotes(newBase);
        setEditedNotes(new Map());
        setDebouncedEdits(new Map());
        setUpdating(false);
        setSubmitted(false);
        doneCb();
      } catch {
        setNetworkError(true);
        setUpdating(false);
      }
    }

    void apply();
  }, [
    submitted,
    updating,
    editedNotes,
    currentNotes,
    setDebouncedEdits,
    debouncedEdits,
    doneCb,
  ]);

  return (
    <div className="flex flex-col gap-8 oveflow-hidden w-full">
      <div className="flex flex-col gap-2">
        <div className="text-lg-medium text-gray-900">Add Descriptions</div>
        <div className="text-sm-light text-gray-500">
          Write notes on individual photos using the fields below each image.
        </div>
      </div>
      <div className="justify-center grow overflow-y-auto gap-y-4 gap-x-2 p-[2px] grid-cols-[repeat(auto-fit,minmax(min-content,_19rem))] auto-rows-min grid  ">
        {images.map((el) => (
          <Card
            key={el.id}
            className="h-[15rem] lg:h-[23rem] flex flex-col"
            standardSize={false}
          >
            <img
              className="object-cover object-center h-[6rem] lg:h-[13rem]"
              src={el.url}
            ></img>
            <TextInput
              className="grow px-4 pb-4 pt-2"
              label="Description"
              name="Description"
              placeholder="Optionally enter details such as people, places, or events"
              type="textarea"
              value={editedNotes.get(el.id) ?? currentNotes.get(el.id)}
              onInput={(val) => {
                const newEdits = new Map(editedNotes);
                newEdits.set(el.id, val);
                setEditedNotes(newEdits);
              }}
            ></TextInput>
          </Card>
        ))}
      </div>
      <div className="flex flex-row justify-end gap-3">
        <Button
          hasColor={false}
          displayType="secondary"
          label="Cancel"
          disabled={!!submitted}
          onClick={() => {
            setSubmitted('canceled');
          }}
        ></Button>
        <Button
          disabled={!!submitted}
          onClick={() => {
            setSubmitted(true);
          }}
          label="Submit"
        ></Button>
      </div>
    </div>
  );
}

export default function ImageUploader(props: any) {
  const navigate = useNavigate();
  const [batch, refreshBatch] = useRequest(client.getCurrentUploadBatch.query);
  const { created } = useOutletContext<OutletCtx>();
  const [uploadTitle, setUploadTitle] = useState('');
  const [uploadDescription, setUploadDescription] = useState('');
  const [filesToUpload, setFilesToUpload] = useState<
    Array<{
      file: File;
      preview: string;
    }>
  >([]);
  const [multiUpload, setMultiUpload] = useState<MultiUploader>();
  const batchId = batch?.data?.id;

  return (
    <Modal classname="h-auto lg:h-[41rem] w-[79rem]">
      {!(batch.state === 'loading') && batch.data?.finished ? (
        <BatchSaver
          title={uploadTitle}
          description={uploadDescription}
          batchId={batch.data.id}
          initialImages={batch.data.archiveImage.map((img) => ({
            id: img.id,
            adminNotes: img.metaData.adminNotes,
            url: img.url,
          }))}
          done={() => {
            created();
            navigate('..');
          }}
        />
      ) : multiUpload && !(batch.state === 'loading') ? (
        <UploadProgress uploadProgress={multiUpload} />
      ) : batchId !== undefined ? (
        <>
          <Uploader
            title={uploadTitle}
            setTitle={setUploadTitle}
            description={uploadDescription}
            setDescription={setUploadDescription}
            files={filesToUpload}
            setFiles={setFilesToUpload}
            onCancel={() => {
              navigate('..');
            }}
            onUpload={() => {
              const uploader = new MultiUploader(batchId, filesToUpload);
              uploader.start();
              const subscription = () => {
                if (uploader.getSnapshot().type === 'finished') {
                  uploader.unsubscribe(subscription);
                  refreshBatch();
                }
              };
              uploader.subscribe(subscription);
              setMultiUpload(uploader);
            }}
          />
          <LoadingOverlay loading={batch.state === 'loading'} />
        </>
      ) : null}
    </Modal>
  );
}
