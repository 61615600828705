import { trpc } from 'shared/trpc';

export default function JobViewer() {
  const query = trpc.jobs.useSWR(undefined, { refreshInterval: 1000 });
  return (
    <div>
      {query.data?.map((el) => (
        <div key={el.name}>{JSON.stringify(el)}</div>
      ))}
    </div>
  );
}
