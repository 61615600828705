import CollectionManager from 'client/components/CollectionManager';
import { ArchiveImageCollection } from 'client/components/ArchiveImageCollectionManager';
import { MainNavigation } from 'client/components/MainNavigation';

import MedicalCrossIcon from 'assets/svg/tabler/medical-cross.svg';
import GiftIcon from 'assets/svg/tabler/gift.svg';
import UploadIcon from 'assets/svg/tabler/upload.svg';
import { Login } from './login';
import { LocationCollection } from 'client/components/LocationCollectionManager';
import { CreateLocation } from 'client/components/CreateLocation';
import AdminPage from 'client/components/AdminPage';
import ManageContentPage from 'client/components/ManageContentPage';
import JobViewer from 'client/components/JobViewer';
import StorageViewer from 'client/components/StorageViewer';
import ImageUploader from 'client/components/ImageUploader';
import { Navigate } from 'react-router-dom';
const testCategory = {
  label: 'Current',
  items: [
    {
      title: 'Health Hub',
      subtitle: 'Access compiled health info',
      icon: MedicalCrossIcon,
      to: '/',
    },
    {
      title: 'Directory',
      subtitle: 'View birthdays and addresses',
      icon: GiftIcon,
      to: '/',
    },
    {
      title: 'Submissions',
      subtitle: 'Upload content to archive',
      icon: UploadIcon,
      to: '/',
    },
  ],
};
export const LoggedOutRoutes = [
  {
    element: <MainNavigation categories={[]} />,
    children: [
      {
        path: '/*',
        element: <Login />,
      },
    ],
  },
];

export const Routes = [
  {
    element: (
      <MainNavigation
        categories={[
          testCategory,
          testCategory,
          testCategory,
          testCategory,
          testCategory,
        ]}
      />
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/admin/manage/locations" replace={true} />,
      },
      {
        path: '/admin',
        element: <AdminPage />,
        children: [
          {
            path: '/admin/manage',
            element: <ManageContentPage />,
            children: [
              {
                path: '/admin/manage/locations',
                loader: async () => {
                  console.log('loader called');
                  return null;
                },
                element: (
                  <CollectionManager
                    key="location"
                    collectionType={LocationCollection}
                  />
                ),
                children: [{ path: 'new', element: <CreateLocation /> }],
              },
              {
                path: '/admin/manage/images',
                loader: async () => {
                  console.log('loader called');
                  return null;
                },
                element: (
                  <CollectionManager
                    key="images"
                    collectionType={ArchiveImageCollection}
                  />
                ),
                children: [{ path: 'new', element: <ImageUploader /> }],
              },
              {
                path: '/admin/manage/jobs',
                element: <JobViewer />,
              },
              {
                path: '/admin/manage/storage',
                element: <StorageViewer />,
              },
            ],
          },
        ],
      },
    ],
  },
];

export default Routes;
