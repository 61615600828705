import { type FunctionComponent } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import DashboardIcon from 'assets/svg/tabler/dashboard.svg';
import DatabaseIcon from 'assets/svg/tabler/database.svg';
import EditIcon from 'assets/svg/tabler/edit.svg';
import UserIcon from 'assets/svg/tabler/user.svg';

function NavItem(props: {
  to: string;
  icon: FunctionComponent<{ className?: string }>;
}) {
  const Icon = props.icon;
  return (
    <NavLink to={props.to}>
      {({ isActive }) => {
        return (
          <div
            className={`p-3 rounded-md hover:bg-gray-50 hover:text-gray-600 ${
              isActive ? 'bg-primary-50 text-primary-700' : 'text-gray-700'
            }`}
          >
            <Icon />
          </div>
        );
      }}
    </NavLink>
  );
}
export default function AdminPage() {
  return (
    <div className="flex flex-col lg:flex-row justify-stretch w-full h-full">
      <nav className="py-8 px-4 border-b lg:border-r lg:border-b-0 border-gray-200 flex flex-row lg:flex-col gap-2">
        <NavItem to="/" icon={DashboardIcon} />
        <NavItem to="/admin/manage" icon={DatabaseIcon} />
        <NavItem to="/" icon={EditIcon} />
        <NavItem to="/" icon={UserIcon} />
      </nav>
      <Outlet></Outlet>
    </div>
  );
}
