import { forwardRef } from 'react';

type Props = React.PropsWithChildren<{
  className?: string;
  selected: boolean;
  standardSize?: boolean;
  [x: string]: any;
}>;

export const Card = forwardRef<HTMLDivElement, Props>((props, ref) => {
  let { className, selected, children, standardSize, ...rest } = props;
  standardSize = standardSize ?? true;
  className = className ?? '';
  return (
    <div
      ref={ref}
      className={`${selected ? 'selected' : ''} ${
        standardSize ? 'h-[9rem] w-[10rem] lg:h-[13.5rem] lg:w-[15rem]' : ''
      }
       ${className} group rounded-lg bg-base-white overflow-hidden border border-gray-200 selected:ring-[2px] selected:ring-primary-600 selected:border-primary-600 shadow-sm relative`}
      {...rest}
    >
      {children}
    </div>
  );
});
Card.displayName = 'Card';
export default Card;
