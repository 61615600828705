export function LoadingIndicator(props: any) {
  return (
    <div className="flex flex-col gap-4 items-center">
      <svg viewBox="0 0 32 32" className="fill-none w-8 h-8 animate-spin">
        <path
          d=" M 28 16 A 12 12 0 0 1 6 22"
          className="stroke-primary-600"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div className="text-sm-medium text-gray-700">Loading...</div>
    </div>
  );
}
