import Button, { ReactRouterLinkButton } from './Button';
import Modal from './Modal';
import { CreateLocationRequest } from 'shared/validators';
import { type ZodAny, type z } from 'zod';
import { trpc, client } from 'shared/trpc';
import { useNavigate, useOutletContext } from 'react-router-dom';
import LocationPicker from './LocationPicker';
import AddTagPicker from './AddTagPicker';
import XIcon from 'assets/svg/tabler/x.svg';
import { useState } from 'react';
import ErrorTextInput from './ErrorTextInput';
import LoadingOverlay from './LoadingOverlay';

export interface OutletCtx {
  created: () => void;
}

function mapObject<K extends string, V, Q>(
  obj: { [Prop in K]?: V } & Record<string, V>,
  f: (val: V) => Q
): Record<K, Q> {
  const result: Record<string, Q> = {};
  for (const [k, v] of Object.entries(obj)) {
    result[k] = f(v);
  }
  return result;
}

function mergeErrors<T extends ZodAny>(
  a: z.inferFlattenedErrors<T>,
  b?: z.inferFlattenedErrors<T>
) {
  if (!b) {
    return a;
  }
  const formErrors = a.formErrors.concat(b.formErrors);
  const fieldErrors: Record<string, string[]> = {};
  for (const [k, v] of Object.entries(a.fieldErrors)) {
    const err: string[] = [];
    if (Array.isArray(v)) {
      for (const el of v) {
        if (typeof el === 'string') {
          err.push(el);
        }
      }
    }
    if (err.length !== 0) {
      fieldErrors[k] = err;
    }
  }
  for (const [k, v] of Object.entries(b.fieldErrors)) {
    const err: string[] = [];
    if (Array.isArray(v)) {
      for (const el of v) {
        if (typeof el === 'string') {
          err.push(el);
        }
      }
    }
    if (err.length !== 0) {
      if (k in fieldErrors) {
        fieldErrors[k] = err.concat(fieldErrors[k]);
      } else {
        fieldErrors[k] = err;
      }
    }
  }
  return { formErrors, fieldErrors };
}

function useZodForm<T extends z.ZodTypeAny>(
  schema: T,
  def: z.infer<T>,
  request: (
    val: z.infer<T>
  ) => Promise<
    { success: true } | { success: false; error: z.inferFlattenedErrors<T> }
  >,
  options: { surpressErrorUntilInput?: boolean } = {
    surpressErrorUntilInput: true,
  }
) {
  const [formObject, setFormObject] = useState<z.infer<T>>(def);
  const validated = schema.safeParse(formObject);
  const [serverErrorObject, setServerErrorObject] = useState<
    z.inferFlattenedErrors<T>
  >({ formErrors: [], fieldErrors: {} });
  const [touched, setTouched] = useState(false);
  const [inflight, setInflight] = useState(false);
  const errorObject = !(touched && options?.surpressErrorUntilInput)
    ? { formErrors: [], fieldErrors: {} }
    : mergeErrors(
        serverErrorObject,
        validated.success ? undefined : validated.error.flatten()
      );

  function updateFormObject(obj: z.infer<T>) {
    if (!touched) {
      setTouched(true);
    }
    setServerErrorObject({ fieldErrors: {}, formErrors: [] });
    setFormObject(obj);
  }

  async function submit() {
    if (inflight) {
      return;
    }
    setInflight(true);
    const validated = schema.safeParse(formObject);
    if (!validated.success) {
      const errors = validated.error.flatten();
      setServerErrorObject(errors);
      return;
    }
    try {
      const result = await request(validated.data);

      if (result.success) {
        setServerErrorObject({ formErrors: [], fieldErrors: {} });
        return;
      }
      setServerErrorObject(result.error);
    } catch {
      setServerErrorObject({ formErrors: ['Unknown error'], fieldErrors: {} });
    }
    setInflight(false);
  }

  return {
    form: formObject,
    updateForm: updateFormObject,
    errors: errorObject,
    canSubmit: validated.success && !inflight,
    submit,
    inflight,
  };
}
export function CreateLocation(props: any) {
  const { created } = useOutletContext<OutletCtx>();
  const navigate = useNavigate();
  const request = trpc.createLocation.useSWRMutation();
  const { form, updateForm, errors, canSubmit, submit, inflight } = useZodForm(
    CreateLocationRequest,
    { title: '', tags: [], adminNotes: '' },
    async (val) => {
      const result = await client.createLocation.mutate(val);
      if (result.error) {
        return { success: false, error: result.data };
      } else {
        created();
        navigate('..');
        return { success: true };
      }
    }
  );

  return (
    <Modal>
      <form
        className="flex flex-col gap-8 relative w-[45rem]"
        onSubmit={(e) => {
          e.preventDefault();
          // TODO: promise await?
          void submit();
        }}
      >
        <button
          type="button"
          className="absolute top-0 right-0 p-2.5"
          onClick={() => {
            navigate('..');
          }}
        >
          <XIcon />
        </button>
        <div className="flex flex-col gap-5 w-full h-full">
          <div className="flex flex-col gap-2">
            <div className="text-lg-medium text-gray-900">Add Location</div>
            <div className="text-sm-light text-gray-500">
              Add a new location to the database.
            </div>
            {request.error ? 'Error!' : null}
          </div>
          <div className="flex flex-col gap-4 p-1 overflow-y-auto">
            <ErrorTextInput
              placeholder="Location Title"
              name="Location Title"
              label="Location Title"
              type="text"
              required
              value={form.title}
              setValue={(val) => {
                updateForm({ ...form, title: val });
              }}
              errors={errors.fieldErrors.title}
            />

            <LocationPicker
              locationPicked={(loc) => {
                if (loc) {
                  updateForm({
                    ...form,
                    coordinates: {
                      ...loc,
                      googlePlace: loc.googlePlace ?? undefined,
                    },
                  });
                } else {
                  updateForm({ ...form, coordinates: undefined });
                }
              }}
            />
            <AddTagPicker
              label="Tags"
              tagIds={form.tags}
              textArea
              setTagIds={(ids) => {
                updateForm({ ...form, tags: ids });
              }}
            />

            <ErrorTextInput
              placeholder="Admin Notes"
              name="Admin Notes"
              label="Admin Notes"
              type="textarea"
              value={form.adminNotes}
              setValue={(val) => {
                updateForm({ ...form, adminNotes: val });
              }}
              errors={errors.fieldErrors.adminNotes}
            />
          </div>
          <div className="flex gap-3 flex-row justify-end">
            <ReactRouterLinkButton
              displayType="secondary"
              hasColor={false}
              size="lg"
              label="Cancel"
              to=".."
            ></ReactRouterLinkButton>
            <Button
              displayType="primary"
              size="lg"
              label="Confirm"
              type="submit"
              disabled={!canSubmit}
            />
          </div>
        </div>
      </form>
      <LoadingOverlay loading={inflight} />
    </Modal>
  );
}
