import { useState, useEffect, useCallback, useMemo } from 'react';
import SearchTagPicker from 'client/components/SearchTagPicker';
import { useIntQueryParam, useQueryParam, useQueryParamEnum } from '../hooks';
import { SelectableCard } from './SelectableCard';

import { makeObservable, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react-lite';
import { delay, ignorePromise, intersection, useConstant } from 'shared/utils';
import { Outlet } from 'react-router-dom';
import Button, { ReactRouterLinkButton } from './Button';
import { LoadingIndicator } from './LoadingIndicator';
import QueryPagination from './QueryPagination';
import Bar from './Bar';
import {
  OrderDirection,
  type Order,
  type ResourceType,
} from 'shared/validators';
import { type z } from 'zod';
import AscIcon from 'assets/svg/tabler/sort-ascending.svg';
import DescIcon from 'assets/svg/tabler/sort-descending.svg';
import TagIcon from 'assets/svg/tabler/tag.svg';
import SearchIcon from 'assets/svg/tabler/search.svg';
import TextInput from './TextInput';
import Dropdown from './Dropdown';
import TagFilter from './TagFilter';
import LoadingOverlay from './LoadingOverlay';

export interface Query {
  search: string;
  tags: number[];
  order?: z.infer<typeof Order>;
  uploadBatch?: number;
}

export type EditType = 'single' | 'multi' | 'none';
export type State = 'loading' | 'idle' | 'error';

export interface ArchiveCollection<Item extends { id: number }, Edit> {
  validateEdits: (edits: Edit) => {
    fieldErrors: Map<string, string[]>;
    formErrors: string[];
  };
  createEmptyEdit: () => Edit;
  createMultiEdit: (base: Item, edit: Edit) => Edit;
  isEdited: (base: Item, edit: Edit) => boolean;

  inspector: React.FunctionComponent<{
    editor: ArchiveCollectionEditor<Item, Edit>;
    update: (state: 'loading' | 'done') => void;
    state: State;
  }>;
  itemViewer: React.FunctionComponent<{
    editor: ArchiveCollectionEditor<Item, Edit>;
    item: Item;
  }>;
  resource: z.infer<typeof ResourceType>;
  query: (q: Query) => Promise<Item[]>;
}

export type Selection<Item extends { id: number }, Edit> =
  | { type: 'multi'; selected: Item[]; edit: Edit }
  | { type: 'single'; selected: [Item]; edit: Edit }
  | undefined;

export class ArchiveCollectionEdit<Item extends { id: number }, Edit> {
  collectionType: ArchiveCollection<Item, Edit>;
  currentCollection: Item[];
  inprogressEdits: Map<number, Edit>;
  selection: Selection<Item, Edit>;

  constructor(
    collection: Item[],
    collectionType: ArchiveCollection<Item, Edit>
  ) {
    makeObservable(this, {
      selection: observable,
      size: computed,
      selectionIds: computed,
      stringKey: computed,
      updateSelection: action,
      updateCollection: action,
      updateEdit: action,
      selectAll: action,
      selectNone: action,
      select: action,
      deselect: action,
      toggleSelect: action,
      isEdited: computed,
    });
    this.collectionType = collectionType;
    this.currentCollection = [...collection];
    this.inprogressEdits = new Map();
  }

  get size() {
    if (!this.selection) {
      return 0;
    }
    return this.selection.selected.length;
  }

  get selectionIds() {
    return new Set(this.selection?.selected.map((el) => el.id));
  }

  get stringKey() {
    if (!this.selection) {
      return 'none';
    }
    if (this.selection.type === 'multi') {
      return 'multi';
    }
    return this.selection.selected[0].id.toString();
  }

  updateSelection(newSelection: Set<number>) {
    const foundItems = new Map<number, Item>();
    for (const item of this.currentCollection) {
      if (newSelection.has(item.id)) {
        foundItems.set(item.id, item);
      }
    }

    if (foundItems.size === 0) {
      // early exit, just delete the selection
      this.selection = undefined;
      return;
    }

    if (foundItems.size === 1) {
      // We are selection only one element, switch to in progress edit
      const [item] = foundItems.values();
      this.selection = {
        type: 'single',
        selected: [item],
        edit:
          this.inprogressEdits.get(item.id) ??
          this.collectionType.createEmptyEdit(),
      };
      return;
    }

    // We are selecting multiple Elements
    if (this.selection) {
      // transform old selection if it exists
      if (this.selection.type === 'single') {
        // Use current edit as the base for multi edit
        this.selection = {
          type: 'multi',
          selected: [...foundItems.values()],
          edit: this.collectionType.createMultiEdit(
            this.selection.selected[0],
            this.selection.edit ?? this.collectionType.createEmptyEdit()
          ),
        };
      } else {
        // keep old multi edit active
        this.selection.selected = [...foundItems.values()];
      }
    } else {
      // Create a new multi selection
      this.selection = {
        type: 'multi',
        selected: [...foundItems.values()],
        edit: this.collectionType.createEmptyEdit(),
      };
    }
  }

  updateCollection(collection: Item[]) {
    this.currentCollection = [...collection];
    if (this.selection) {
      const oldSelection = new Set<number>();
      for (const item of this.selection.selected) {
        oldSelection.add(item.id);
      }
      this.updateSelection(oldSelection);
    }
  }

  updateEdit(edit: Edit) {
    if (this.selection) {
      this.selection.edit = edit;
      if (this.selection.type === 'single') {
        const [base] = this.selection.selected;
        if (this.isEdited) {
          // store edit as in progress
          this.inprogressEdits.set(base.id, edit);
        } else {
          // mark edit as done
          this.inprogressEdits.delete(base.id);
        }
      }
    }
  }

  selectAll() {
    const selection = new Set<number>();
    for (const item of this.currentCollection) {
      selection.add(item.id);
    }
    this.updateSelection(selection);
  }

  selectNone() {
    this.updateSelection(new Set());
  }

  select(id: number) {
    const newSelection = new Set(this.selectionIds);
    newSelection.add(id);
    this.updateSelection(newSelection);
  }

  deselect(id: number) {
    const newSelection = new Set(this.selectionIds);
    newSelection.delete(id);
    this.updateSelection(newSelection);
  }

  toggleSelect(id: number) {
    const newSelection = new Set(this.selectionIds);
    if (newSelection.has(id)) {
      newSelection.delete(id);
    } else {
      newSelection.add(id);
    }
    this.updateSelection(newSelection);
  }

  get isEdited() {
    if (!this.selection) {
      return false;
    }
    if (this.selection.type === 'multi') {
      return true;
    }
    return this.collectionType.isEdited(
      this.selection.selected[0],
      this.selection.edit
    );
  }
}

export class ArchiveCollectionEditor<Item extends { id: number }, Edit> {
  currentCollection: Item[];
  submissionFieldErrors: Map<string, string[]>;
  submissionErrors: string[];
  collectionType: ArchiveCollection<Item, Edit>;
  edits: ArchiveCollectionEdit<Item, Edit>;

  constructor(
    collection: Item[],
    collectionType: ArchiveCollection<Item, Edit>
  ) {
    makeObservable(this, {
      currentCollection: observable,
      edits: observable,
      submissionFieldErrors: observable,
      submissionErrors: observable,
      errors: computed,
      isValid: computed,
      setSubmissionErrors: action,
      updateCollection: action,
    });

    this.currentCollection = [...collection];
    this.edits = new ArchiveCollectionEdit(
      this.currentCollection,
      collectionType
    );
    this.collectionType = collectionType;
    this.submissionFieldErrors = new Map();
    this.submissionErrors = [];
  }

  setSubmissionErrors(errors?: {
    formErrors: string[];
    fieldErrors: Map<string, string[]>;
  }) {
    if (errors) {
      this.submissionErrors = errors.formErrors;
      this.submissionFieldErrors = errors.fieldErrors;
    } else {
      this.submissionErrors = [];
      this.submissionFieldErrors = new Map();
    }
  }

  get errors() {
    if (!this.edits.selection) {
      return {
        fieldErrors: this.submissionFieldErrors,
        formErrors: this.submissionErrors,
      };
    }
    // TODO: remove emptyEditCreation
    const { fieldErrors, formErrors } = this.collectionType.validateEdits(
      this.edits.selection.edit ?? this.collectionType.createEmptyEdit()
    );
    formErrors.push(...this.submissionErrors);

    for (const [k, v] of this.submissionFieldErrors) {
      const oldErrors = fieldErrors.get(k) ?? [];
      oldErrors.push(...v);
      fieldErrors.set(k, oldErrors);
    }
    return { fieldErrors, formErrors };
  }

  get isValid() {
    return (
      this.errors.formErrors.length === 0 && this.errors.fieldErrors.size === 0
    );
  }

  updateCollection(collection: Item[]) {
    this.currentCollection = [...collection];
    this.edits.updateCollection(this.currentCollection);
  }
}

const itemsPerPage = 10;
const CollectionManager = observer(
  <Item extends { id: number }, Edit>(props: {
    collectionType: ArchiveCollection<Item, Edit>;
  }) => {
    const [search, setSearch] = useQueryParam('search');
    const searchQuery = search[0] ?? '';
    const [queryTagIds, setQueryTagIds] = useQueryParam('tags');
    const [batchId, _] = useQueryParam('batch');
    const parsedBatch = useMemo(
      () => (batchId[0] ? parseInt(batchId[0]) : undefined),
      [batchId]
    );
    const [order, setOrder] = useQueryParamEnum(
      'order',
      OrderDirection,
      'desc'
    );
    const parsedQueryTags = useMemo(
      () => queryTagIds.map((e) => parseInt(e)),
      [queryTagIds]
    );
    const [queryState, setQueryState] = useState<State>('loading');
    const collectionEditor = useConstant(
      () => new ArchiveCollectionEditor([], props.collectionType)
    );
    let [page] = useIntQueryParam('page');
    page ??= 0;
    const numPages = Math.ceil(
      collectionEditor.currentCollection.length / itemsPerPage
    );
    if (page >= numPages - 1) {
      page = numPages - 1;
    }
    const currentPage = collectionEditor.currentCollection.slice(
      page * itemsPerPage,
      (page + 1) * itemsPerPage
    );
    const fetchNew = useCallback(async () => {
      // TODO: fix stacking of loads, switch to swr?
      setQueryState('loading');
      try {
        const newCollection = await props.collectionType.query({
          search: searchQuery,
          tags: parsedQueryTags,
          order: {
            type: 'created',
            direction: order,
          },
          uploadBatch: parsedBatch,
        });
        // TODO: This is mean...
        //await delay(1000);
        collectionEditor.updateCollection(newCollection);
        setQueryState('idle');
      } catch (e) {
        console.error(e);
        setQueryState('error');
      }
    }, [
      collectionEditor,
      props.collectionType,
      parsedQueryTags,
      order,
      searchQuery,
      parsedBatch,
    ]);
    useEffect(() => {
      // TODO: error?
      void fetchNew();
    }, [fetchNew]);

    async function tagSelectionUpdated(e: number[]) {
      setQueryTagIds(e.map((el) => el.toString()));
    }

    async function update(state: 'loading' | 'done') {
      if (state === 'loading') {
        setQueryState('loading');
      } else if (state === 'done') {
        try {
          const newCollection = await props.collectionType.query({
            search: searchQuery,
            tags: parsedQueryTags,
            order: { type: 'created', direction: order },
          });
          // TODO: bigInt can not be serizalized...
          // console.log(`new collection: ${JSON.stringify(newCollection)}`);
          collectionEditor.updateCollection(newCollection);
          setQueryState('idle');
        } catch (e) {
          setQueryState('error');
          console.log(e);
        }
      }
    }

    return (
      <div className="pt-12 pb-24 flex flex-col gap-8 w-full h-full bg-gray-25">
        <div className="px-8 flex flex-col gap-5">
          <div className="flex flex-row justify-between items-start gap-4">
            <div className="display-sm-medium">Manage!</div>
            <TextInput
              type="text"
              name="Search"
              placeholder="Search"
              leadingIcon={SearchIcon}
              value={searchQuery}
              onInput={(val) => {
                setSearch([val]);
                fetchNew();
              }}
            />
          </div>
          <div className="flex flex-row flex-wrap">
            <Dropdown label="Tags" leadingIcon={TagIcon}>
              <TagFilter
                resource={collectionEditor.collectionType.resource}
                selected={parsedQueryTags}
                updateSelection={ignorePromise(tagSelectionUpdated)}
              />
            </Dropdown>
            <Button
              hasColor={false}
              displayType="tertiary"
              size="sm"
              label="Select all"
              onClick={() => {
                collectionEditor.edits.selectAll();
              }}
            />
            <Button
              hasColor={false}
              displayType="tertiary"
              size="sm"
              label="Select none"
              onClick={() => {
                collectionEditor.edits.selectNone();
              }}
            />
          </div>
        </div>
        <div className="flex flex-col px-8 gap-6">
          <div className="flex flex-row justify-between flex-wrap gap-6">
            <Bar className="tabular-nums">
              <div className="flex justify-center items-center text-sm-semibold bg-gray-700 text-base-white py-2.5 px-4">
                {collectionEditor.edits.size}
              </div>
              <div className="flex justify-center items-center text-sm-light text-gray-700 py-2.5 px-4">
                <span className="text-sm-semibold">
                  {collectionEditor.currentCollection.length}&nbsp;
                </span>
                Results
              </div>
            </Bar>
            <Bar
              as="button"
              onClick={() => {
                if (queryState === 'loading') {
                  return;
                }
                if (order === 'asc') {
                  setOrder('desc');
                } else {
                  setOrder('asc');
                }
                fetchNew();
              }}
            >
              <div className="flex justify-center items-center text-sm-light text-gray-700 py-2.5 px-4">
                Order by Date Added
              </div>
              <div className="flex justify-center items-center text-sm-light text-gray-700 py-2.5 px-4">
                {order === 'asc' ? (
                  <AscIcon className="h-5 w-5" />
                ) : (
                  <DescIcon className="h-5 w-5" />
                )}
              </div>
            </Bar>
            <ReactRouterLinkButton size="md" label="Add New" to="new" />
          </div>
          <div className="relative flex flex-row items-end gap-4 px-8 flex-wrap-reverse justify-center">
            <div className="grow p-[2px] grid gap-2 lg:gap-6 justify-start grid-cols-[repeat(auto-fit,_10rem)] lg:grid-cols-[repeat(auto-fit,_15rem)] auto-rows-min">
              {currentPage.map((el) => (
                <SelectableCard
                  selectChanged={() => {
                    collectionEditor.edits.toggleSelect(el.id);
                  }}
                  disabled={queryState !== 'idle'}
                  selected={collectionEditor.edits.selectionIds.has(el.id)}
                  className="flex flex-col"
                  key={el.id}
                >
                  <props.collectionType.itemViewer
                    key={el.id}
                    item={el}
                    editor={collectionEditor}
                  />
                </SelectableCard>
              ))}
            </div>

            <props.collectionType.inspector
              editor={collectionEditor}
              // TODO: error
              update={ignorePromise(update)}
              state={queryState}
            />
            <LoadingOverlay loading={queryState === 'loading'} />
          </div>
          <QueryPagination queryParameter="page" numPages={numPages} />
          <Outlet context={{ created: fetchNew }} />
        </div>
      </div>
    );
  }
);

export default CollectionManager;
