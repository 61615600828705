import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { delay } from 'shared/utils';
declare global {
  var BUILD_VERSION: string;
}

export default function init(reactRoot: React.FunctionComponent<any>) {
  (BigInt.prototype as any).toJSON = function () {
    return this.toString();
  };

  console.log(`Environment: ${process.env.NODE_ENV ?? ''}`);

  if (process.env.NODE_ENV === 'development') {
    let serverId: string | null = null;

    async function getServeId() {
      try {
        const resp = await fetch('/api/reload_long');
        const id = await resp.text();
        return id;
      } catch {
        console.log(
          'server offline, switchting to fast polling until it is online'
        );
        while (true) {
          try {
            const resp = await fetch('/api/reload');
            const id = await resp.text();
            return id;
          } catch {
            await delay(500);
          }
        }
      }
    }

    async function poller() {
      while (true) {
        const newId = await getServeId();
        console.log(`New server ID: ${newId}`);
        if (newId !== serverId) {
          console.log('reloading');
          window.location.reload();
        }
      }
    }

    async function startReloadMonitor(): Promise<void> {
      while (true) {
        try {
          const resp = await fetch('/api/reload');
          serverId = await resp.text();
          console.log(`Server ID: ${serverId}`);
          break;
        } catch (e) {
          await delay(2000);
        }
      }
      await poller();
    }

    void startReloadMonitor();
  }

  const container = document.querySelector('#root');
  if (!container) {
    console.error('Unable to find react root');
  } else {
    const root = ReactDOM.createRoot(container);
    root.render(React.createElement(reactRoot));
  }
}
